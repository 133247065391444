<template>
  <div class="container-fluid">
    <div class="row mt-2">
      <div class="col-md-3 tw-mb-6 md:tw-mb-0">
        <div class="list-group">
          <a
              v-for="tabName of tabNames"
              class="list-group-item list-group-item-action"
              @click="_changeTab(tabName)"
              :class="{active: tabName === activeTab}"
          >
            {{ startCase(tabName.split('_').join(' ')) }}
          </a>
        </div>
      </div>
      <div
          class="col-md-9"
          :class="{
                'loader-wrapper': loadingInProgress
              }"
          v-if="loadingInProgress"
      >
        <div
            v-if="loadingInProgress"
            class="spinner-wrapper"
            :class="{
                'loader-wrapper': loadingInProgress
              }"
        >
          <ve-progress
              :progress="50"
              loading
              :size="40"
              :angle="-90"
          />
        </div>
      </div>
      <div
          class="col-md-9"
          v-cloak
          v-if="!loadingInProgress"
      >
        <div class="tabs-content">
          <div
              id="panel1v"
              class="tabs-panel"
          >
            <div v-show="itemsCount">
              <h4 ref="items-count">
                {{ itemsCount }} pages found
              </h4>
            </div>
            <span v-show="!itemsCount && !loadingInProgress">No Records Found.</span>
            <div
                v-for="item of items"
                :key="item.id"
                class="media tw-flex-wrap position-relative border-bottom py-3 pr-4"
            >
              <video-preview-thumbnail
                  class="tw-max-w-[295px]"
                  :video-host="item.preview && item.preview.host"
                  :show-preview="item.preview && (currentPlayingPreviewVideoId === item.preview.id)"
                  :iframe-id="item.preview && `history-preview-` + item.preview.id"
                  :video-id="item.preview && item.preview.id"
                  :page-url="item.url"
                  :thumbnail-url="item.thumbnail"
                  :preview-url="item.preview_url"
                  :title="item.title"
                  :user-authenticated="true"
                  :is-watched="item.is_watched"
                  :watched-percent="item.watched_percent"
                  :video-duration="item.video_duration_minutes"
                  @mouse-on-thumbnail="(videoId) => mouseOnVideoThumbnail(item.preview.id, 'history-preview-' + item.preview.id)"
                  @mouse-out-thumbnail="mouseOutVideoThumbnail"
              ></video-preview-thumbnail>
              <div class="media-body ml-3 tw-mt-auto md:tw-mt-0 tw-flex tw-flex-col md:tw-h-[166px]">
                <h6 class="tw-hidden md:tw-block">
                  <a
                      :href="'/golf-instruction/' + item.slug"
                  >
                    {{ item.title }}
                  </a>
                </h6>
                <div
                    class="description tw-hidden md:tw-block"
                >
                  <span class="tw-text-ellipsis tw-overflow-hidden tw-line-clamp-4">
                    {{ item.description }}
                  </span>
                </div>
                <div class="small tw-mt-auto tw-block">
                  <em>Date added {{ dayjs(item.publish_date).format('MMM D, YYYY HH:mm') }}</em>
                </div>
              </div>
              <h6 class="tw-block md:tw-hidden tw-mt-2 tw-w-full">
                <a
                    :href="'/golf-instruction/' + item.slug"
                >
                  {{ item.title }}
                </a>
              </h6>
            </div>
            <simple-pager
                class="tw-mt-2"
                :page="currentPage"
                :limit="10"
                :count="itemsCount"
                @page-changed="_onPageChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseCtrlMixIn from '../utils/baseController.js';
import VideoPreviewMixin from '../mixins/VideoPreviewMixin.vue';
import VideoPreviewThumbnail from '../components/VideoPreviewThumbnail.vue';
import { Deferred, Server } from '../utils/helpers.js';
import * as helpers from '../utils/helpers.js';
import dayjs from 'dayjs';
import SimplePager from '../components/simplePager.vue';
import { startCase } from 'lodash';

const hlp = helpers.appHelper;

export default {
  name: 'CtrlPageHistory',
  el: '#video-list-page-main-content',
  mixins: [baseCtrlMixIn, VideoPreviewMixin],
  components: { SimplePager, VideoPreviewThumbnail },
  data() {
    return {
      activeTab: 'most_recent',
      tabNames: ['most_recent', 'most_viewed', 'longest', 'shortest'],
      items: [],
      itemsCount: 0,
      currentPage: 1,
      loadingInProgress: true,
    };
  },
  async created() {
    const sortingFilter = window.location.pathname.split('/').at(-2);
    if (sortingFilter === 'most-viewed') this.activeTab = 'most_viewed';
    if (sortingFilter === 'recently-added') this.activeTab = 'most_recent';
    if (sortingFilter === 'longest') this.activeTab = 'most_recent';
    if (sortingFilter === 'shortest') this.activeTab = 'most_recent';

    await this.getVideos(1);
  },
  mounted() {
    $('[v-cloak]').get().forEach((element) => { element.removeAttribute('v-cloak'); });
  },
  methods: {
    dayjs,
    startCase,
    async getVideos(page) {
      this.loadingInProgress = true;
      const url = `/api/pages/?sorting=${this.activeTab}&page=` + page;

      this.currentPage = page;
      try {
        const data = await Server.get(url);
        this.items = data.results;
        this.itemsCount = data.count;
      } finally {
        this.loadingInProgress = false;
      }
    },
    async _onPageChanged(newPage) {
      await this.getVideos(newPage);

      const { offsetTop } = this.$refs['items-count'];
      $(window).scrollTop(offsetTop);
    },
    _changeTab(newTabName) {
      this.activeTab = newTabName;
      this.itemsCount = null;
      this.items = [];

      this.getVideos(1);
    },
  },
};
</script>
