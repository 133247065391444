/* eslint-disable consistent-return */

import { $rootScope } from './base.js';
import { VIDEO_HOSTS } from './constants.js';
import { appHelper as helpers } from './helpers.js';
import YouTubePlayer from 'youtube-player';

export default class VideoPlayerFacade {
  constructor(playerType, iframeId, videoKey = null) {
    this.player = null;
    this.playerType = VideoPlayerFacade._getPlayerType(playerType);
    this.youtubePlayerReady = false;
    this.iframeId = iframeId;

    if (this.playerType === 'youtube') {
      this.initYouTubePlayer(iframeId, videoKey);
    } else if (this.playerType === 'vimeo') {
      this.initVimeoPlayer(iframeId);
    } else {
      throw new Error('Unsupported player type. Choose either "youtube" or "vimeo".');
    }
  }

  initYouTubePlayer(iframeId, videoKey) {
    if (videoKey) {
      this.player = YouTubePlayer(iframeId, {
        videoId: videoKey,
        playerVars: {
          mute: 1,
          controls: 0,
          modestbranding: 1,
          rel: 0,
          fs: 0,
          iv_load_policy: 3,
          autohide: 1,
        },
      });
    } else this.player = YouTubePlayer(iframeId);
    this.player.on('stateChange', (event) => {
      this.onYoutubeStateChange(event);
    });
    this.player.on('ready', (event) => {
      this.onYoutubePlayerReady(event);
    });
  }

  onYoutubePlayerReady() {
    this.youtubePlayerReady = true;
    $rootScope.$emit('player_ready', this.iframeId);
  }

  onYoutubeStateChange(ytEvent) {
    if (ytEvent.data === 1) {
      $rootScope.$emit('YtVideo_play', this.iframeId);
    } else if (ytEvent.data === 0) {
      $rootScope.$emit('YtVideo_end', { event: ytEvent });
    }
  }

  initVimeoPlayer(iframeId) {
    this.player = new window.Vimeo.Player(iframeId);
    this.player.ready().then(() => {
      $rootScope.$emit('player_ready', iframeId);
    });
    helpers.forEach(['play', 'pause', 'ended'], (evtName) => {
      this.player.on(evtName, (originalEvent) => {
        $rootScope.$emit('vimeoEvent_' + evtName, {
          vmPlayer: this.player,
          event: evtName,
          element: document.getElementById(iframeId),
          originalEvent,
        });
      });
    });
  }

  play() {
    if (this.playerType === 'youtube') {
      this.player.playVideo();
    } else if (this.playerType === 'vimeo') {
      this.player.play();
    }
  }

  pause() {
    if (this.playerType === 'youtube') {
      this.player.pauseVideo();
    } else if (this.playerType === 'vimeo') {
      this.player.pause();
    }
  }

  stop() {
    if (this.playerType === 'youtube') {
      this.player.stopVideo();
    } else if (this.playerType === 'vimeo') {
      this.player.pause().then(() => this.setCurrentTime(0));
    }
  }

  unmute() {
    if (this.playerType === 'youtube') {
      this.player.unMute();
    } else if (this.playerType === 'vimeo') {
      this.player.setMuted(false);
      this.player.setVolume(1);
    }
  }

  setCurrentTime(seconds) {
    if (this.playerType === 'youtube') {
      this.player.seekTo(seconds);
    } else if (this.playerType === 'vimeo') {
      this.player.setCurrentTime(seconds);
    }
  }

  async getCurrentTime() {
    if (this.playerType === 'youtube') {
      return (parseInt(await this.player.getCurrentTime(), 10));
    }
    if (this.playerType === 'vimeo') {
      return this.player.getCurrentTime();
    }
  }

  async getMutedState() {
    if (this.playerType === 'youtube') {
      return this.player.isMuted();
    }
    if (this.playerType === 'vimeo') {
      return this.player.getMuted();
    }
  }

  static _getPlayerType(playerType) {
    if (playerType === 'youtube' || playerType === VIDEO_HOSTS.YOUTUBE) {
      return 'youtube';
    }
    if (playerType === 'vimeo' || playerType === VIDEO_HOSTS.VIMEO) {
      return 'vimeo';
    }
  }

  // eslint-disable-next-line getter-return
  get isEnded() {
    if (this.playerType === 'youtube') {
      return this.player.getPlayerState() === 0;
    }
    if (this.playerType === 'vimeo') {
      return this.player.getEnded() === 'ended';
    }
  }
}
