<template>
  <div
      v-if="isAuthenticated"
      class="tw-leading-6 tw-relative"
      v-click-outside="hidePlaylist"
  >
    <b-icon
        @click.prevent="toggleDropdown"
        :class="iconClasses"
        :icon="icon"
    ></b-icon>
    <div
        v-if="showPlaylistDropDown"
        class="dropdown-menu dropdown-menu-right !tw-min-w-max text-left shadow playlist-dropdown scrollable-dropdown-half"
        :class="{
          'scrollable-dropdown': user_playlists.length > 10,
        }"
    >
      <form novalidate :name="`playlistForm.playlist_form-${pageId}`" class="custom-form" cc-form-enter-press
            form-enter-press=".create_playlist" @submit="$event.preventDefault()">

        <h5 class="dropdown-header22 pl-3">Add to...</h5>
        <div class="dropdown-divider"></div>
        <span class="dropdown-item-text" v-if="!user_playlists.length">No Playlists</span>

        <div v-if="user_playlists.length && showPlaylistDropDown" v-for="item in user_playlists"
             class="dropdown-item-text">
          <div class="">
            <input :id="'checkbox-' + item.id" class="checkbox-custom pr-5 d-inline-block"
                   v-model="item.is_checked"
                   @change="SaveToPlaylist(item, pageId)"
                   :name="'checkbox-' + item.id"
                   type="checkbox">
            <label :for="'checkbox-' + item.id" class="checkbox-custom-label"
                   v-text="item.name"></label>
            <span class="float-right">
              <b-icon icon="lock" v-if="item.playlist_type==3"></b-icon>
            </span>
          </div>
        </div>
        <div
            class="dropdown-item-text"
            v-show="!showAddPlaylist"
            @click="showAddPlaylist = true"
        >
          <span class="pr-5 d-inline-block"><b-icon icon="plus"></b-icon> Create New Playlist</span>
        </div>
        <div v-show="showAddPlaylist" id="create_playlist" class="dropdown-item-text">
          <div class="form-group" :class="{'not-empty': playlist.name.length}">
            <input placeholder="Enter playlist name..." v-set-dirty
                   set-dirty="playlistForm.playlist_name_dirty"
                   :class="{'text-danger': playlistForm.playlist_name_dirty && !playlist.name}"
                   type="text" required maxlength="50" class="form-control"
                   v-model="playlist.name" id="playlist_name"
                   v-enter-press="{fn: savePlaylist, params: [null, playlist]}"
                   enter-press="#create_playlist_btn"/>
          </div>
          <div v-show="playlistForm.playlist_name_dirty && !playlist.name"
               class="help-text text-danger text-left">Required
          </div>
          <div :class="{'text-danger': playlistForm.playlist_name_dirty && !playlist.name}"
               class="help-text text-right" id="passwordHelpText">
            <span v-text="playlist.name.length || 0"></span>/50
          </div>
          <div class="text-right">
            <a @click="savePlaylist($event, playlist)"
               :class="{disabled: playlistForm.playlist_name_dirty && !playlist.name}"
               class="create_playlist btn btn-primary" id="create_playlist_btn">CREATE</a>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div v-else class="text-right tw-leading-6">
    <div
        v-click-outside="hidePlaylist"
        class="tw-relative"
    >
      <b-icon
          :class="`tw-h-${iconSize} tw-w-${iconSize} ${iconClasses}`"
          :icon="icon"
          @click.prevent="toggleDropdown"
      ></b-icon>
      <div
          v-if="showPlaylistDropDown"
          class="dropdown-menu dropdown-menu-right text-left p-2"
      >
        <form novalidate :name="`playlistForm.playlist_form-${pageId}`" class="custom-form">
          <p>Want to watch this again later?</p>
          <p><a @click="(e) => $emit('signInTriggered', e)" tabindex="0"
                class="text-primary">Sign in</a> or <a @click="$emit('signUpTriggered')" tabindex="0"
                                                       class="text-primary">join now</a> to add it
            to a playlist.</p>
        </form>
      </div>
    </div>
  </div>

</template>

<script>
import { appHelper as helper, Server } from '../utils/helpers.js'
import { BIcon } from 'bootstrap-vue'
import NotificationMixin from '&/mixins/NotificationMixin.vue'
import _ from 'lodash'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'WatchLaterModal',
  components: { BIcon },
  mixins: [NotificationMixin],
  directives: {
    ClickOutside,
  },
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
    pageId: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      default: 'three-dots-vertical',
    },
    iconSize: {
      type: Number,
      default: 3,
    },
    iconClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['signInTriggered', 'signUpTriggered', 'dropdownToggled'],
  data () {
    return {
      playlistForm: {
        playlist_name_dirty: false,
        playlist_name: '',
        showErrors: false,
      },
      showPlaylistDropDown: false,
      showAddPlaylist: false,
      playlist: {
        playlist_name: '',
        name: '',
        playlist_type: 1,
        is_checked: true,
      },
      storageRefreshKey: 0,
    }
  },
  mounted () {
    this.popupItem = this.$el
  },
  methods: {
    hidePlaylist () {
      this.showAddPlaylist = false
      this.showPlaylistDropDown = false
    },
    SaveToPlaylist (playlist, pageId) {
      const data = {
        playlist_id: playlist.id,
        page_id: pageId,
        is_checked: playlist.is_checked,
      }
      Server.post(this.getPlaylistUrl(), data)
          .then(() => {
            if (playlist.is_checked) {
              this.notifSuccess('Added To Playlist')
            } else {
              this.notifSuccess('Removed From Playlist.')
            }
          })
    },
    savePlaylist (evt, playlist) {
      const { playlistForm } = this

      playlistForm.showErrors = true
      if (this.playlist.name) {
        Server.post(this.getPlaylistUrl(), playlist)
            .then((data) => {
              this.toggleDropdown()
              if (data.success) {
                this.notifSuccess('Added To Playlist')
                this.getUserPlaylist()
                this.showPlaylistDropDown = false
                this.$nextTick(() => { // we need to recreate playlist because something is breaking in bootstrap...
                  this.showPlaylistDropDown = true
                })
              } else {
                this.notifError(data.message)
              }

              this.playlist.name = ''
              playlistForm.playlist_name_dirty = false
            })
        return true
      }
      if (evt && evt.stopPropagation) {
        evt.stopPropagation()
      }
      return false
    },
    resetAddPlaylistPopup () {
      this.showAddPlaylist = false
      this.playlist.playlist_name = ''
      this.playlist.name = ''
    },
    getPlaylistUrl () {
      let url = '/api/playlist/'
      this.playlist.page_id = this.pageId
      url += '?page_id=' + this.pageId
      return url
    },
    getUserPlaylist () {
      this.resetAddPlaylistPopup()
      Server.get(this.getPlaylistUrl()).then((data) => {
        helper.localStorage.set('user_playlists', data)
        this.storageRefreshKey += 1
      })
    },
    toggleDropdown () {
      if (this.showPlaylistDropDown) {
        this.hidePlaylist()
      } else {
        this.showPlaylistDropDown = !this.showPlaylistDropDown
        if (this.isAuthenticated) this.getUserPlaylist()
      }
      this.$emit('dropdownToggled', this.showPlaylistDropDown)
    },
  },
  computed: {
    user_playlists () {
      _.noop(this.storageRefreshKey) // this is needed for recomputation
      // TODO: migrate to pinia
      return helper.localStorage.get('user_playlists') || {}
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
  display: block;
}

.show {
  top: 0
}
</style>
