<script>
import baseCtrlMixIn from '../utils/baseController.js';
import BrowseVideos from '../components/VideoTreeselect.vue';
import ExpandableBlock from '../components/ExpandableBlock.vue';
import MostViewedPages from '../components/MostViewedPages.vue';
import MostRecentPages from '../components/MostRecentPages.vue';
import RecentlyWatchedPages from '../components/RecentlyWatchedPages.vue';

export default {
  name: 'CtrlBrowseVideos',
  el: '.browse-videos',
  mixins: [baseCtrlMixIn],
  components: {
    BrowseVideos, MostViewedPages, RecentlyWatchedPages, MostRecentPages, ExpandableBlock,
  },
  data() {
    return {
      videoPreviewPlaying: false,
      mostRecentPagesLoaded: false,
      recentlyWatchedPagesLoaded: false,
      mostViewedPagesLoaded: false,
    };
  },
  methods: {
    onVideoPreviewPlayingStatusChange(playingStatus) {
      this.videoPreviewPlaying = playingStatus;
    },
    trySetContainerHeight() {
      const contentPartHeight = document.getElementById('contentPart').scrollHeight;
      const browsePageContainer = document.getElementById('treeselectContainer');
      browsePageContainer.style.maxHeight = contentPartHeight + 'px';
    },
  },
};
</script>
