<script>
import { $rootScope } from '../utils/base.js';
import { Server } from '../utils/helpers.js';

export default {
  name: 'VideoEventsMixin',
  data() {
    return {
      historySaved: false,
    };
  },
  mounted() {
    $rootScope.$on('player_ready', () => {
      this.onPlayerReady();
    });
    $rootScope.$on('YtVideo_play', (iframeId) => {
      this.videoPlaying = true;
    });
    $rootScope.$on('vimeoEvent_play', (evt) => {
      this.videoPlaying = true;
    });
    $rootScope.$on('YtVideo_pause', () => {
      this.videoPlaying = false;
    });
    $rootScope.$on('vimeoEvent_pause', (evt) => {
      this.videoPlaying = false;
    });
    $rootScope.$on('YtVideo_end', () => {
      $('body').addClass('overlay-mode');
      this.videoPlaying = false;
    });
    $rootScope.$on('vimeoEvent_ended', (evt) => {
      $('body').addClass('overlay-mode');
      this.videoPlaying = false;
    });
  },
  methods: {
    savePageView() {
      if (!window.user_id) return;
      if (this.historySaved) {
        return;
      }
      this.historySaved = true;
      Server.post(`/api/page/history/${window.page_id}/views/`).then(() => {});
    },
    async onPlayerReady() {
      let previousSeconds = 0;
      if (window.user_id) {
        setInterval(async () => {
          const currentSeconds = parseInt(await window.currentPlayer.getCurrentTime(), 10);
          if (currentSeconds > 0 && currentSeconds !== previousSeconds) {
            Server.patch(`/api/page/history/${window.page_id}`, { seconds_watched: currentSeconds });
            previousSeconds = currentSeconds;
          }
        }, 5000);
      }
      this.savePageView();
    },
  },
};
</script>
