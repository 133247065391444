var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pages.length)?_c('div',{staticClass:"tw-min-w-14"},[_c('h5',{staticClass:"tw-mb-2"},[_vm._v("Recommended content")]),_vm._v(" "),_c('div',{staticClass:"tw-relative tw-z-0 tw-pl-4"},[(!_vm.isMobileDev)?_c('span',{staticClass:"tw-absolute tw-top-1/3 tw-left-0 tw-z-20 tw-h-8 tw-w-4 tw-bg-black tw-shadow-md tw-inline-flex tw-items-center tw-justify-center tw-pointer-events-auto hover:tw-opacity-80",class:{
            'tw-invisible': _vm.isPrevDisabled,
            'tw-cursor-pointer': !_vm.isPrevDisabled
          },on:{"click":_vm.prev}},[_c('b-icon',{staticClass:"tw-h-5 tw-w-5 tw-text-white",attrs:{"icon":"chevron-compact-left"}})],1):_vm._e(),_vm._v(" "),(!_vm.isMobileDev)?_c('span',{staticClass:"tw-absolute tw-top-1/3 tw-left-full tw-z-20 tw-bg-black tw-h-8 tw-w-4 tw-shadow-md tw-inline-flex tw-items-center tw-justify-center tw-pointer-events-auto tw-text-white hover:tw-opacity-80",class:{
          'tw-invisible': _vm.isNextDisabled,
          'tw-cursor-pointer': !_vm.isNextDisabled
        },on:{"click":_vm.next}},[_c('b-icon',{staticClass:"tw-h-5 tw-w-5",attrs:{"icon":"chevron-compact-right"}})],1):_vm._e(),_vm._v(" "),_c('swiper',_vm._b({ref:"swiper",class:{
          'swiper-no-swiping': _vm.swiperOptions.noSwiping,
          '!tw-overflow-hidden': !_vm.dropdownOpened
        },on:{"swiper":(instance) => {
            _vm.swiper = instance;
          }}},'swiper',_vm.swiperOptions,false),[_vm._l((_vm.courses.entries()),function([index, course]){return _c('swiper-slide',{key:`related-course-${index}`,staticClass:"slide"},[_c('course-preview',{attrs:{"course":course}})],1)}),_vm._v(" "),_vm._l((_vm.pages.entries()),function([index, page]){return _c('swiper-slide',{key:`related-page-${index}`,staticClass:"slide"},[_c('most-viewed-video-card',{ref:`page-` + page.id,refInFor:true,staticClass:"tw-text-wrap",attrs:{"pageUrl":page.url,"title":page.title,"description":page.description,"thumbnailUrl":page.thumbnail,"previewUrl":page.preview_url,"videoId":page.preview && page.preview.id,"videoHost":page.preview && page.preview.host,"videoDuration":page.video_duration_minutes,"userAuthenticated":_vm.$root.isAuthenticated,"isWatched":page.is_watched,"showPreview":page.preview && _vm.currentPlayingPreviewVideoId === page.preview.id,"order":page.sort_order,"page-id":page.id,"publish-date":page.publish_date,"iframe-id":page.preview && _vm._getVideoPreviewIframeId(page),"watched-percent":page.watched_percent},on:{"mouse-on-thumbnail":(videoId) => _vm.mouseOnVideoThumbnail(videoId, _vm._getVideoPreviewIframeId(page)),"mouse-out-thumbnail":_vm.mouseOutVideoThumbnail,"dropdownToggled":dropdownState => _vm.dropdownOpened = dropdownState}})],1)})],2)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }