<template>
  <div
      class=""
      :class="{
        'tw-bg-gray-200': isHighlighted,
        'hover:tw-bg-zinc-100': !isHighlighted
      }"
  >
    <watch-later-modal
        class="tw-top-0.5 tw-right-2.5 !tw-absolute tw-z-[100]"
        :is-authenticated="userAuthenticated"
        :page-id="pageId"
        icon="plus-circle-fill"
        icon-classes="tw-bg-white tw-h-6 tw-w-6 tw-right-0 tw-rounded-full"
        @signInTriggered="$root.openAuthBox($event, fullPath)"
        @signUpTriggered="$root.showModal('signup')"
        @dropdownToggled="dropdownState => $emit('dropdownToggled', dropdownState)"
    ></watch-later-modal>
    <video-preview-thumbnail
      :video-host="videoHost"
      :show-preview="showPreview"
      :iframe-id="iframeId"
      :video-id="videoId"
      :page-url="pageUrl"
      :preview-url="previewUrl"
      :thumbnail-url="thumbnailUrl"
      :title="title"
      :user-authenticated="userAuthenticated"
      :is-watched="isWatched"
      :watched-percent="watchedPercent"
      :video-duration="videoDuration"
      @mouse-on-thumbnail="mouseOnThumbnail = true; $emit('mouse-on-thumbnail', videoId, iframeId)"
      @mouse-out-thumbnail="mouseOnThumbnail = false; $emit('mouse-out-thumbnail')"
    />
    <div class="tw-w-full tw-h-full tw-absolute tw-top-0 tw-bg-black tw-bg-opacity-20">
        <h6
            v-show="!mouseOnThumbnail"
            class="tw-absolute tw-mb-0 tw-bottom-0 tw-p-1.5 tw-basis-[100px] tw-text-white tw-text-wrap tw-font-normal tw-text-sm
                   md:tw-text-lg center tw-grow tw-line-clamp-2 tw-text-ellipsis
                   tw-w-4/5 tw-text-center"
        >
          <span>{{ title }}</span>
        </h6>
      </div>
  </div>
</template>

<script>
import { BIcon } from 'bootstrap-vue';
import { getXTimeAgo } from '../utils/helpers.js';
import WatchLaterModal from './WatchLaterModal.vue';
import { VIDEO_HOSTS } from '../utils/constants.js';
import VideoPreviewThumbnail from './VideoPreviewThumbnail.vue'

export default {
  name: 'MostViewedVideoCard',
  emits: ['mouse-on-thumbnail', 'mouse-out-thumbnail', 'dropdownToggled'],
  props: {
    pageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    thumbnailUrl: {
      type: String,
      required: true,
    },
    previewUrl: {
      type: String,
      required: false,
    },
    isWatched: {
      type: Boolean,
      required: true,
    },
    watchedPercent: {
      type: Number,
      required: false,
    },
    videoDuration: {
      type: String,
      required: true,
    },
    videoId: {
      type: Number,
      required: true,
    },
    videoHost: {
      type: Number,
      required: true,
    },
    userAuthenticated: {
      type: Boolean,
      required: true,
    },
    showPreview: {
      type: Boolean,
      required: true,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Number,
      required: false,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    pageId: {
      type: Number,
      required: true,
    },
    iframeId: {
      type: String,
      required: true,
    },
    publishDate: {
      type: String,
      required: true,
    },
  },
  components: { VideoPreviewThumbnail, WatchLaterModal, BIcon },
  data() {
    return {
      mouseOnThumbnail: false,
    };
  },
  computed: {
    VIDEO_HOSTS() {
      return VIDEO_HOSTS;
    },
    fullPath() {
      return window.location.pathname + window.location.search;
    },
  },
  methods: {
    getXTimeAgo,
    goToVideo() {
      window.open(this.pageUrl, '_self');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
