<script>
import { BToast } from 'bootstrap-vue'

export default {
  name: 'NotificationMixin',
  components: { BToast },
  methods: {
    notification(message, variant, title, options) {
      options = options || {};
      let opts = {
        variant,
        solid: true,
      };
      if (title) {
        opts.title = title;
      }
      opts = $.extend(opts, options);
      this.$root.$bvToast.toast(message, opts);
    },
    notifInfo(message, title, options) {
      this.notification(message, 'default', title, options);
    },
    notifError(message, title, options) {
      this.notification(message, 'danger', title, options);
    },
    notifSuccess(message, title, options) {
      this.notification(message, 'success', title, options);
    },
  },
};
</script>
