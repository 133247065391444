<template>
  <div
      class="tw-relative tw-@container/recent"
      v-if="pages.length"
  >
    <div class="tw-flex tw-mb-4">
      <h5 class="tw-w-max tw-mb-0">Recently added</h5>
      <a
          href="/golf-instruction/recently-added"
          class="tw-ml-2 tw-self-start tw-text-sm tw-text-black hover:tw-no-underline hover:tw-opacity-70 hover:tw-text-black"
      >view all</a>
    </div>
    <div class="tw-flex">
      <div class="tw-ml-2 tw-flex-grow tw-min-w-[10px]">
        <swiper
            ref="swiper"
            class="tw-z-20"
            v-bind="swiperOptions"
            :class="{ 'swiper-no-swiping': swiperOptions.noSwiping }"
            @swiper="
              (instance) => {
                swiper = instance;
              }
            "
        >
          <swiper-slide
              v-for="[index, page] of pages.entries()"
              :key="index"
          >
            <div
                id="leftColumn"
                class="tw-absolute @lg/recent:tw-left-8 tw-top-[10%] tw-flex tw-flex-col tw-max-h-[80%] tw-justify-center
                       tw-pl-2.5 @lg/recent:tw-pl-4 tw-h-full tw-z-[100] tw-w-[90%] @xl/recent:tw-w-[45%] tw-text-white "
            >
              <h6 class="tw-text-base @xl/recent:tw-text-xl tw-line-clamp-2
                         @lg/recent:tw-line-clamp-3 @xl/recent:tw-line-clamp-3 tw-overflow-y-hidden tw-text-ellipsis"
              >{{
                  page.title
                }}</h6>
              <div
                  id="description"
                  class="
                    tw-hidden @sm/recent:tw-block tw-overflow-y-hidden
                    tw-text-xs @md/recent:tw-text-sm @lg/recent:tw-text-base tw-text-ellipsis
                    @md/recent:tw-line-clamp-2 @lg/recent:tw-line-clamp-4 @xl/recent:tw-line-clamp-6
                  "
              >
                {{ page.description }}
              </div>
              <div
                  class="tw-flex tw-flex-wrap @lg/recent:tw-flex-nowrap tw-items-center tw-gap-2 tw-h-min-content tw-mt-auto">
                <button
                    class="
                      tw-btn tw-pl-2 @xl/recent:tw-pl-1.5 @xl/recent:tw-pr-3.5
                      tw-font-semibold tw-text-sm @sm/recent:tw-text-base
                    "
                    @click="goToVideo(page)"
                >
                  <b-icon class="tw-h-8 tw-w-8 tw-text-black" icon="play-fill"/>
                  <span class="tw-hidden @xl/recent:tw-inline">Play</span>
                </button>
                <button
                    class="
                      tw-flex tw-basis-[fit-content] tw-justify-center tw-items-center tw-bg-black tw-bg-opacity-60
                      tw-border-0 tw-rounded-sm tw-py-1 tw-px-2 @xl/recent:tw-pr-1.5 @xl/recent:tw-pl-3.5
                      hover:tw-opacity-70 tw-cursor-pointer tw-whitespace-nowrap
                      tw-font-semibold tw-text-sm @sm/recent:tw-text-base tw-text-white hover:tw-text-white
                    "
                    @click.stop="triggerWatchLaterOpening(page.id)"
                >
                  <span class="tw-hidden @xl/recent:tw-inline tw-relative tw-text-nowrap">Watch Later</span>
                  <b-icon
                      class="tw-text-white tw-h-8 tw-w-8"
                      icon="plus"
                  ></b-icon>
                </button>
                <watch-later-modal
                    :ref="`watch-later-trigger-page-${page.id}`"
                    class="tw-top-0.5 tw-relative"
                    :is-authenticated="$root.isAuthenticated"
                    :page-id="page.id"
                    icon="plus"
                    icon-classes="!tw-hidden"
                    @signInTriggered="$root.openAuthBox($event, fullPath)"
                    @signUpTriggered="$root.showModal('signup')"
                ></watch-later-modal>
              </div>
            </div>

            <div
                class="tw-relative"
            >
              <div
                  @click="goToVideo(page)"
                  class="tw-absolute tw-cursor-pointer tw-bg-black tw-bg-opacity-20 tw-aspect-video
                         tw-rounded-2xl tw-w-full tw-h-[98%] tw-z-50"
              />
              <iframe
                  v-if="activePage.id === page.id && page.preview.host === VIDEO_HOSTS.VIMEO"
                  v-show="iframeLoaded"
                  :id="`id-preview-video-frame-${page.id}`"
                  class="tw-w-full tw-h-full tw-border-0 tw-aspect-video tw-rounded-2xl"
                  :src="getVideoUrl(page)"
                  webkitAllowFullScreen
                  mozallowfullscreen
                  allowFullScreen
                  video-host="vimeo"
                  allow="autoplay"
                  @load="iframeLoaded = true; startAutoplay(page)"
              ></iframe>
              <div
                  class="video-container tw-border-0 tw-aspect-video tw-rounded-2xl"
                  :class="{
                       'tw-w-0 tw-h-0': !iframeLoaded
                  }"
                  v-if="activePage.id === page.id && page.preview.host === VIDEO_HOSTS.YOUTUBE"
              >
                <div
                    class="tw-aspect-video tw-rounded-2xl tw-border-0 tw-w-full tw-h-full"
                    :id="`id-preview-video-frame-${page.id}`"
                    :src="getVideoUrl(page)"
                    webkitAllowFullScreen mozallowfullscreen allowFullScreen
                    video-host="youtube"
                > <!-- for YT videos "iframeLoaded" is changed via watcher, as there is no iframe to use @load" -->
                </div>
              </div>
              <img
                  v-if="activePage.id !== page.id || (!iframeLoaded)"
                  class="tw-w-full tw-h-full tw-border-0 tw-aspect-video tw-rounded-2xl"
                  :class="{
                    'tw-blur': activePage.id === page.id && !iframeLoaded
                  }"
                  :src="page.thumbnail || '/static/img/gsa-instruction-default.jpg'"
                  :alt="page.title"
              >
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <span
        class="tw-absolute tw-top-1/2 -tw-left-4 @lg:tw-left-2 tw-bg-black tw-z-30 tw-h-17 tw-w-6 tw-shadow-md
               tw-inline-flex tw-items-center tw-justify-center tw-pointer-events-auto hover:tw-opacity-80"
        :class="{
            'tw-invisible': isPrevDisabled,
            'tw-cursor-pointer': !isPrevDisabled
          }"
        @click="prev"
    >
        <b-icon class="tw-h-12 tw-w-12 tw-text-white" icon="chevron-compact-left"/>
      </span>
    <span
        class="tw-absolute tw-top-1/2 tw-left-full tw-bg-black tw-z-30 tw-h-17 tw-w-6 tw-shadow-md
               tw-inline-flex tw-items-center tw-justify-center tw-pointer-events-auto hover:tw-opacity-80"
        :class="{
            'tw-invisible': isNextDisabled,
            'tw-cursor-pointer': !isNextDisabled
          }"
        @click="next"
    >
        <b-icon class="tw-h-12 tw-w-12 tw-text-white" icon="chevron-compact-right"/>
      </span>
    <div>
      <div class="tw-m-auto"></div>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css';
import { appHelper as helper, extractYoutubeEmbeddedVideoCode, Server } from '../utils/helpers.js';
import { BIcon } from 'bootstrap-vue';
import { VIDEO_HOSTS } from '../utils/constants.js';
import WatchLaterModal from './WatchLaterModal.vue';
import SwiperMixin from '../mixins/SwiperMixin.vue';
import { Swiper, SwiperSlide } from 'swiper-vue2';
import VideoPlayerFacade from '../utils/VideoPlayerFacade.js';
import { $rootScope } from '../utils/base.js';

export default {
  name: 'MostRecentVideos',
  components: {
    BIcon, WatchLaterModal, Swiper, SwiperSlide,
  },
  mixins: [SwiperMixin],
  props: {
    videoPreviewPlaying: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['most-recent-pages-loaded'],
  data() {
    return {
      VIDEO_HOSTS,
      pages: [],
      swiperOptions: {
        noSwiping: !helper.isMobileDev(),
        breakpoints: {
          320: {
            slidesPerView: 0.98,
            spaceBetween: 40,
          },
          560: {
            slidesPerView: 0.98,
            spaceBetween: 40,
          },
          767: {
            slidesPerView: 0.98,
            spaceBetween: 40,
          },
          992: {
            slidesPerView: 0.98,
            spaceBetween: 40,
          },
          1101: {
            slidesPerView: 1.2,
            spaceBetween: 40,
          },
        },
      },
      currentPlayer: null,
      iframeLoaded: false,
      slideNextTimeoutId: null,
    };
  },
  async created() {
    await this.loadMostRecentPages();
    this.$emit('most-recent-pages-loaded');
    $rootScope.$on('player_ready', (iframeId) => {
      if (iframeId === 'id-preview-video-frame-' + this.activePage.id && this.activePage.preview.host === VIDEO_HOSTS.YOUTUBE) this.iframeLoaded = true;
    });
  },
  mounted() {
    this.$watch('activePage', () => {
      this.iframeLoaded = false;
      setTimeout(() => {
        if (this.activePage.preview.host === VIDEO_HOSTS.YOUTUBE) this.startAutoplay(this.activePage);
      }, 300);
    });
  },
  methods: {
    async loadMostRecentPages() {
      const url = '/api/pages/?sorting=most_recent&skip_watched_percent=true';
      const response = await Server.get(url);
      this.pages = response.results;

      return response;
    },
    getVideoUrl(page) {
      if (page.preview.host === VIDEO_HOSTS.VIMEO) {
        const videoQuality = helper.isMobileDev() ? '360p' : '540p';
        return page.preview_url + `?enablejsapi=1&controls=false&keyboard=false&texttrack=en-x-autogen&muted=true&title=false&autoplay=false&autopause=false&loop=true&quality=${videoQuality}`;
      }
      if (page.preview.host === VIDEO_HOSTS.YOUTUBE) return page.preview_url + `?enablejsapi=1&autoplay=0&loop=1&playlist=${page.preview.key}&mute=1&controls=0&modestbranding=1&rel=0&fs=0&iv_load_policy=3&autohide=1`;
    },
    startAutoplay(page) {
      if (this.slideNextTimeoutId) clearTimeout(this.slideNextTimeoutId);
      const iframeId = 'id-preview-video-frame-' + page.id;
      const videoKey = extractYoutubeEmbeddedVideoCode(page.preview_url);
      this.currentPlayer = new VideoPlayerFacade(page.preview.host, iframeId, videoKey);
      this.currentPlayer.play();

      this.slideNextTimeoutId = setTimeout(() => {
        if (this.activePage !== this.pages[this.pages.length - 1]) {
          this.next();
        } else {
          this.swiper.slideTo(0); // start the rotation loop from the beginning
        }
      }, 25000);
    },
    goToVideo(page) {
      window.open(page.url, '_self');
    },
    triggerWatchLaterOpening(pageId) {
      this.$refs[`watch-later-trigger-page-${pageId}`][0].toggleDropdown();
    },
  },
  computed: {
    isMobileDev() {
      return helper.isMobileDev();
    },
    activePage() {
      if (this.swiper) return this.pages[this.swiper.activeIndex];
      return this.pages[0];
    },
  },
  watch: {
    videoPreviewPlaying(newValue) {
      if (newValue) this.currentPlayer.pause();
      else this.currentPlayer.play();
    },
  },
};
</script>
