import Vue from 'vue';
import jquery from 'jquery';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import * as PfBase from '&/utils/base';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Sticky from 'vue-sticky-directive';
import VueTippy, { TippyComponent } from 'vue-tippy';
import vueDebounce from 'vue2-debounce';
import VueEllipseProgress from 'vue-ellipse-progress';

window.jquery = jquery;
window.$ = jquery;
require('&/utils/helpers');

const { PfVue } = PfBase;

const requireControllers = require.context(
  // The relative path of the components folder
  './controllers',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /Ctrl\w+\.(vue|js)$/,
);

const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /\w+\.(vue|js)$/,
);

const requireDirectives = require.context(
  // The relative path of the components folder
  './directives',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /Directive\w+\.(vue|js)$/,
);

requireDirectives.keys().forEach((fileName) => {
  requireDirectives(fileName);
});

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Strip the leading `./` and extension from the filename
      fileName.replace(/^\.\/(.*)\.\w+$/, '$1'),
    ),
  );

  // Register component globally
  const configData = componentConfig.default || componentConfig;
  Vue.component(
    configData.name || componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig,
  );
});
PfVue.use(vueDebounce, { lock: true, defaultTime: '400ms', listenTo: 'keyup' });
PfVue.use(BootstrapVue);
PfVue.use(IconsPlugin);
PfVue.use(Sticky);
Vue.use(VueTippy);
PfVue.use(VueEllipseProgress, 've-progress');
// eslint-disable-next-line
Vue.component('tippy', TippyComponent);

const controllers = [];

requireControllers.keys().forEach((fileName) => {
  // Get component config
  const controllerConfig = requireControllers(fileName);
  let controllerName = upperFirst(
    camelCase(
      // Strip the leading `./` and extension from the filename
      fileName.replace(/^\.\/(.*)\.\w+$/, '$1'),
    ),
  );

  // Register controller
  const ctrlCfg = controllerConfig.default || controllerConfig;
  controllerName = ctrlCfg.name || controllerName;
  controllers.push([controllerName, ctrlCfg]);
});

// eslint-disable-next-line no-new
controllers.forEach((ctrl) => { PfVue.register(ctrl[0], ctrl[1]); });

window.addEventListener('load', () => {
  PfVue.run();
});

export default {
};
