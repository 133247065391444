import { $rootScope } from '&/utils/base'; // eslint-disable-line import/no-useless-path-segments
import * as helpers from '&/utils/helpers';
import baseCtrlMixIn from '&/utils/baseController';
import jquery from 'jquery';
import { appHelper as helper } from '../utils/helpers.js';

const $ = jquery;

const hlp = helpers.appHelper;
const { Server } = helpers;

(() => {})(hlp.consoleLog, $);

const CtrlPlaylist = {
  name: 'CtrlPlaylist',
  el: '.ctrl-playlist-controller',
  components: {},
  mixins: [baseCtrlMixIn],
  data() {
    return {
      showEditForm: false,
      initName: '',
      playlist_info: {
        playlist_name: '',
        playlist_id: false,
      },
    };
  },
  created() {
    this.getUserPlaylist();
    $rootScope.$on('playlistSorted', (evt) => {
      this.updateOrder(evt.element, evt.event);
    });
  },
  methods: {
    cancelEditForm() {
      this.showEditForm = false;
      this.playlist_info.playlist_name = this.initName;
    },
    setPlaylistInfo(name, pk) {
      this.playlist_info.playlist_name = name;
      this.initName = name;
      if (pk) {
        this.playlist_info.playlist_id = pk;
      }
    },
    makeEditable() {
      this.showEditForm = true;
    },
    async getUserPlaylist() {
      // TODO: refactor - extract to PlaylistAPI class
      await Server.get(this.getPlaylistUrl()).then((data) => {
        helper.localStorage.set('user_playlists', data);
      });
    },
    updatePlaylistTitle(playlistInfo) {
      const url = this.getPlaylistUrl();
      Server.post(url, playlistInfo)
        .then((data) => {
          this.setPlaylistInfo(playlistInfo.playlist_name);
          this.cancelEditForm();
          this.getUserPlaylist();
          if (data.success) {
            this.notifSuccess('Playlist updated.');
          }
        });
    },
    resetCounter() {
      $('.sr_num').each((key, val) => {
        $(val).text(key + 1);
      });
    },
    async deletePlaylist(playlistId) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      this.confirm('Do you want to delete?').then(async (data) => {
        if (data) {
          const url2 = '/api/playlist/?playlist_id=' + playlistId;
          await Server.delete(url2);
          await this.getUserPlaylist();
          window.location.href = '/golf-instruction/';
        }
      }, () => {});
    },
    deletePlaylistItem(order) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      this.confirm('Do you want to delete?').then((wantDelete) => {
        if (wantDelete) {
          const url2 = '/api/playlist/' + order;
          Server.delete(url2)
            .then(async (data2) => {
              if (!data2.success) {
                const msg = data2.message || 'Sorry, the operation failed.';
                this.notifError(msg);
              } else {
                this.notifSuccess('Removed From Playlist.');
                $('#neworder-' + order).remove();
                this.resetCounter();
                await this.getUserPlaylist();
              }
            });
        }
      });
    },
    async updateOrder(element) {
      const pk = $(element).data('pk');
      const neworder = [];
      $('[sortable-evt="playlistSorted"] [data-ord-pk]').each((idx, el) => {
        neworder.push(parseInt($(el).data('ord-pk'), 10));
      });
      Server.put(`api/playlist/${pk}`, { neworder }).then(() => {});
      await this.getUserPlaylist();
    },
  },
};
export default CtrlPlaylist;
