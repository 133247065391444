import { PfVue, parse, $rootScope } from '&/utils/base';
import * as helperMod from '&/utils/helpers';
import jquery from 'jquery';
import Sortable from 'sortablejs';

// const Sortable = require('sortablejs');

const helpers = helperMod.appHelper;
const $ = jquery;
const { Server } = helperMod;

(() => {})(helpers);

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

PfVue.directive('autoFocus', {
  inserted(element) { // extra params: binding, vnode
    const attrs = helpers.getAttrs(element);
    element = $(element);
    setTimeout(() => {
      $(attrs.autoFocus).focus();
    });
  },
});

PfVue.directive('getNextNr', {
  inserted(el, binding) {
    const valHolder = $(binding.value);
    let value = parseInt(valHolder.data('nextNrValue') || 0, 10);
    value += 1;
    valHolder.data('nextNrValue', value);
    $(el).text(`${value}. `);
  },
});

PfVue.directive('onResize', {
  inserted(el) {
    const attr = helpers.getAttrs(el);
    const element = $(el);
    const evtName = attr.onResize || 'globalWindowResized';
    const widthAttr = `${evtName}_curr_width`;
    let windowResizeTmHandler;
    let prevVal = element.data(widthAttr);
    if (!prevVal) {
      prevVal = $(window).width();
      element.data(widthAttr, prevVal);
    }
    $(window).resize(() => {
      if (windowResizeTmHandler) {
        clearTimeout(windowResizeTmHandler);
        windowResizeTmHandler = null;
      }
      windowResizeTmHandler = setTimeout(() => {
        windowResizeTmHandler = null;
        const newValue = $(window).width();
        const prvVal = parseInt(element.data(widthAttr), 10);
        const enlarged = newValue > prvVal;
        $rootScope.$emit(evtName, { width: newValue, prevWidth: prvVal, enlarged });
        element.data(widthAttr, newValue);
      }, 500);
    });
  },
});

PfVue.directive('setDirty', {
  inserted(el, binding, vnode) {
    const attr = helpers.getAttrs(el);
    const element = $(el);
    element.off('change.setDirty').on('change.setDirty', function onChangeFn() {
      vnode.context.$forceUpdate();
      $(this).addClass('x-dirty');
      if (attr.setDirty) {
        PfVue.sAssign(vnode, attr.setDirty, true);
      }
    });
  },
});

PfVue.directive('preventDblclick', {
  inserted(element) {
    $(element).on('click', () => {
      $(element).attr('href', '#');
    });
  },
});

PfVue.directive('activeIfUrl', {
  inserted(element, binding) {
    const attr = helpers.getAttrs(element);
    const clsName = attr.activeCls || 'is-active';
    const rgx = new RegExp(binding.value || attr.activeIfUrl);
    let fn = 'removeClass';
    if (window.location.pathname.match(rgx)) {
      fn = 'addClass';
    }
    $(element)[fn](clsName);
  },
});

PfVue.directive('listenForEvent', {
  inserted(element) {
    const attr = helpers.getAttrs(element);
    const evName = attr.listenForEvent;
    if (attr.emitEvent) {
      $(element).off(evName).on(evName, (originalEvent) => {
        $rootScope.$emit(attr.emitEvent, { element, originalEvent, originalEventName: evName });
      });
    }
  },
});

PfVue.directive('emitEvent', {
  bind(element, binding) {
    $rootScope.$emit(binding.value);
  },
});

PfVue.directive('onLoad', {
  inserted(element, binding, vnode) {
    $(element).on('load', function onLoadFn() {
      vnode.context[binding.value]($(this));
    });
  },
});

PfVue.directive('groupSwitchActive', {
  inserted(el) {
    const element = $(el);
    const attr = helpers.getAttrs(el);
    element.find('[group-switcher]').click(function clickFn(evt) {
      const mySwitchable = $(this).closest('[group-switchable]');
      const currState = mySwitchable.hasClass('active');
      element.find('[group-switchable]').removeClass('active');
      mySwitchable.toggleClass('active', !currState);

      const namespace = attr.groupSwitchActiveNamespace || 'groupswitchactive';
      if (mySwitchable.hasClass('active')) {
        let contentArea = mySwitchable.find('[group-switchable-content]');
        contentArea = (contentArea.length) ? contentArea : mySwitchable;
        $('body').on('click.' + namespace, {
          el: contentArea,
          actCls: mySwitchable,
        }, (event) => {
          const el2 = event.data.el;
          const { target } = event.originalEvent;
          if (!$.contains(el2[0], target) && target !== el2[0]) {
            $(element).find('[group-switchable].active').removeClass('active');
            $('body').off('click.' + namespace);
          }
        });
      } else {
        $('body').off('click.' + namespace);
      }
      evt.stopPropagation();
    });
  },
});

PfVue.directive('bgImage', {
  bind(el) {
    const attrs = helpers.getAttrs(el);
    const element = $(el);
    element.css('background-image', `url(${attrs.bgImage})`);
  },
});

PfVue.directive('bgBindImage', {
  bind(el, binding, vnode) {
    const attrs = helpers.getAttrs(el);
    const element = $(el);
    const val = parse(attrs.bgBindImage)(vnode);
    element.css('background-image', `url(${val})`);
  },
});

PfVue.directive('bgDynImage', {
  update(el, binding) {
    const element = $(el);
    const val = binding.value;
    const prevVal = element.data('bgDynImage');
    if (val && prevVal !== val) {
      element.css('background-image', `url(${val})`);
      element.data('bgDynImage', val);
    } else if (!val && prevVal) {
      element.css('background-image', 'none');
      element.data('bgDynImage', null);
    }
  },
});

PfVue.directive('stopPropagation', {
  inserted(element) {
    const el = $(element);
    const fnc = (event) => {
      event.stopPropagation();
    };
    el.on('keydown', fnc).on('keyup', fnc);
  },
});

export const textareaExpandDirective = {
  inserted(el) {
    const element = $(el);
    element.css({
      height: 'auto',
      'overflow-y': 'hidden',
    });

    function h(e) {
      setTimeout(() => {
        if (e.style) {
          e.style.height = '';
        }
        $(e).height(e.scrollHeight);
      }, 0);
    }
    h(element);
    element.on('input', function inputFn() {
      h(this);
    });
  },
  update(el, binding) {
    if (binding.expression) {
      const element = $(el);
      const prevValue = element.data('textareaExpandValue');

      if (prevValue !== binding.value) {
        element.data('textareaExpandValue', binding.value);
        setTimeout(() => {
          element.trigger('input');
        }, 100);
      }
    }
  },
};
PfVue.directive('textareaExpand', textareaExpandDirective);

PfVue.directive('staticMaxLines', {
  inserted(el) {
    const attr = helpers.getAttrs(el);
    const element = $(el);
    const lineHeight = parseInt($(element).css('line-height'), 10);
    const lines = parseInt(attr.staticMaxLines, 10);
    const currentHeight = parseInt($(element).height(), 10);
    if (lineHeight && lines && currentHeight && attr.staticMaxLinesSelector) {
      const theHeight = lineHeight * lines + 2;
      if (currentHeight && theHeight) {
        $(attr.staticMaxLinesSelector).not('.static-max-lines-set').addClass('static-max-lines-set').css({
          'max-height': theHeight,
          overflow: 'hidden',
        });
      }
    }
  },
});

PfVue.directive('activeStatus', {
  inserted(el, binding, vnode) {
    const attrs = helpers.getAttrs(el);
    PfVue.sAssign(vnode, attrs.activeStatus, null);
    el.on('focus', () => {
      PfVue.sAssign(vnode, attrs.activeStatus, true);
    }).on('blur', () => {
      PfVue.sAssign(vnode, attrs.activeStatus, false);
    });
  },
});

export const enterPress = {
  inserted(el, binding) {
    const attr = helpers.getAttrs(el);
    const element = $(el);
    element.on('keyup', (event) => {
      if (event.keyCode === 13) {
        if (binding && binding.value && binding.value.fn) {
          const params = binding.value.params || [];
          binding.value.fn(...params);
        } else if (attr.enterPress) {
          $(attr.enterPress).click();
        }
      }
    });
  },
};

PfVue.directive('enterPress', enterPress);
PfVue.directive('switchOffTrigger', {
  inserted(el, binding, vnode) {
    const attr = helpers.getAttrs(el);
    $(attr.switchOffTrigger).on('click.switchOffTrigger', () => {
      if (parse(attr.switchOffValue)(vnode)) {
        PfVue.sAssign(vnode, attr.switchOffValue, false);
        setTimeout(() => {
          vnode.context.$forceUpdate();
        }, 100);
      }
    });
  },
});

export const formEnterPress = {
  inserted(el) {
    setTimeout(() => {
      const attr = helpers.getAttrs(el);
      const element = $(el);
      element.find('input, select').on('keyup', (event) => {
        if (event.keyCode === 13) {
          $(attr.formEnterPress).click();
        }
      });
    });
  },
};

PfVue.directive('formEnterPress', formEnterPress);

export const onceBlured = {
  inserted(el, binding, vnode) {
    const attrs = helpers.getAttrs(el);
    const element = $(el);
    PfVue.sAssign(vnode, attrs.onceBlured, false);
    element.on('blur', () => {
      if (!element.val()) {
        return;
      }
      PfVue.sAssign(vnode, attrs.onceBlured, true);
      setTimeout(() => {
        vnode.context.$forceUpdate();
      }, 0);
    });
  },
};

PfVue.directive('onceBlured', onceBlured);

export const touchShow = {
  inserted(el, binding, vnode) {
    const attrs = helpers.getAttrs(el);
    const element = $(el);
    if (!helpers.isTouchDevice()) {
      return;
    }
    element.on('touchstart', () => {
      PfVue.sAssign(vnode, attrs.touchShow, true);
      setTimeout(() => {
        vnode.context.$forceUpdate();
      }, 0);
    });
    element.on('touchend', () => {
      PfVue.sAssign(vnode, attrs.touchShow, false);
      setTimeout(() => {
        vnode.context.$forceUpdate();
      }, 0);
    });
  },
};

PfVue.directive('touchShow', touchShow);

PfVue.directive('scrollToClass', {
  inserted(el) {
    const attrs = helpers.getAttrs(el);
    const element = $(el);
    setTimeout(() => {
      const actEl = element.find('.' + attrs.scrollToClass);
      if (actEl.length) {
        const scrollCont = element.closest('[y-scrollable-container]');
        const actTop = actEl.offset().top;
        const actHeight = actEl.height();
        const parentTop = element.offset().top;
        const h = scrollCont.height();
        // const h = element.height();
        const offset = actTop - parentTop;
        if (offset - actHeight > h) {
          scrollCont.scrollTop(offset);
        }
      }
    });
  },
});

PfVue.directive('customOnChange', {
  inserted(el, binding, vnode) {
    const attrs = helpers.getAttrs(el);
    const element = $(el);
    element.on('change', () => {
      vnode.context.evaluate(attrs.customOnChange);
    });
  },
});

PfVue.directive('setValue', {
  inserted(el) {
    const attrs = helpers.getAttrs(el);
    const val = attrs.setValueValue;
    const currVal = el.find(attrs.setValueSelector).val();
    if (!currVal) {
      el.find(attrs.setValueSelector).val(val);
    }
  },
});

function scaleImageWrapper(el, parentSel) {
  const imgEl = $(el);
  const parent = imgEl.closest(parentSel);
  const sw = $(window).width();
  const sh = $(window).height();
  const w = imgEl.width();
  const h = imgEl.height();
  parent.removeClass('img-orient-horizontal img-orient-vertical img-orient-overflow');
  if (w && h) {
    const ratio = (sw / sh) / (w / h);
    if (ratio < 1) {
      parent.addClass('img-orient-horizontal');
      if (w > sw * 0.8) {
        parent.addClass('img-orient-overflow');
      }
    } else {
      parent.addClass('img-orient-vertical');
      if (h > sh * 0.7) {
        parent.addClass('img-orient-overflow');
      }
    }
  }
}

PfVue.directive('imgOrientation', {
  inserted(el) {
    const attrs = helpers.getAttrs(el);
    const element = $(el);
    element.on('load', function loadFn() {
      scaleImageWrapper(this, attrs.imgOrientation);
    });
  },
});

PfVue.directive('parseLinks', {
  inserted(el) {
    // const attrs = helpers.getAttrs(el);
    const element = $(el);
    let htmlContent = ' ' + $(element).html() + ' ';
    const links = htmlContent.match(/[^"]https?:\/\/[^\s<]+/gi);
    if (links) {
      helpers.forEach(links, (lnk) => {
        const lnk3 = lnk.replace(/^(.)(https?:\/\/[^\s<]+?)([,.]?)$/i, '<a target="_blank" href="$2">$2</a>$3');
        let lnk2 = '';
        if (lnk.match(/^.https?:\/\/[^?&\s<]*\.(jpg|jpeg|gif|png)([?#].*)?$/i)) {
          const lnk4 = lnk.replace(/^(.)(https?:\/\/[^\s<]+?)([,.]?)$/i, '$2');
          lnk2 = `${lnk[0]}<br><div class="img-widget" style="max-width:80%"><img alt="" src="${lnk4}"></div>${lnk3}`;
        } else if (lnk.match(/^.https?:\/\/(www\.)?youtube\.[a-z]+\//i) || lnk.match(/^.https?:\/\/youtu\.be\//i)) {
          let v = lnk.match(/^.https?:\/\/youtu\.be\/([^,./&?<\s]+)/i);
          v = v || lnk.match(/[&?]v=([^,.&\s<]+)/);
          if (v) {
            lnk2 = `${lnk[0]}<br><div class="yt-widget embed-responsive embed-responsive-16by9">
              <iframe width="560" height="315" class="embed-responsive-item"
              src="https://www.youtube.com/embed/${v[1]}" frameborder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <br>${lnk3}
              </div>`;
          }
        }
        if (!lnk2) {
          lnk2 = `${lnk[0]}${lnk3}`;
        }
        if (lnk2) {
          htmlContent = replaceAll(htmlContent, lnk, lnk2);
        }
      });
      element.html($.trim(htmlContent));
    }
  },
});

PfVue.directive('broadcastOnChange', {
  inserted(el) {
    const attrs = helpers.getAttrs(el);
    const element = $(el);
    element.find('input, select, textarea').on('change', function changeFn(evt) {
      $rootScope.$emit(attrs.broadcastOnChange, evt, this);
    });
    element.on('$destroy', () => {
      element.off();
    });
  },
});

PfVue.directive('sortable', {
  inserted(el, binding) {
    const attrs = helpers.getAttrs(el);
    const options = binding.value || {};
    if (!options.onUpdate) {
      options.onUpdate = (evt) => {
        $rootScope.$emit(attrs.sortableEvt || 'sortableUpdate', { event: evt, element: el });
      };
    }
    const sortable = new Sortable(el, options);
    (() => {})(sortable);
  },
});
PfVue.directive('hideDropdown', {
  inserted(el, binding) {
    $(el).on('hide.bs.dropdown', () => {
      binding.value.fn();
    });
  },
});

PfVue.directive('dropdownPreventClose', {
  inserted(el) {
    const attrs = helpers.getAttrs(el);
    if (attrs.dropdownPreventClose) {
      $(el).on('hide.bs.dropdown', (e) => {
        if (!e.clickEvent) {
          return true;
        }
        const target = $(e.clickEvent.target);
        if (target.hasClass(attrs.dropdownPreventClose) || target.parents('.' + attrs.dropdownPreventClose).length) {
          return false;
        }
        return true;
      });
    }
  },
});

export const stripeForm = {
  inserted(el, binding, vnode) {
    const fn = (tryNr) => {
      tryNr = tryNr || 0;
      if (!window.stripe) {
        if (tryNr > 30) {
          return;
        }
        setTimeout(() => {
          fn(tryNr + 1);
        }, 100);
      } else {
        const stripeStyle = {
          base: {
            color: '#32325d',
            lineHeight: '18px',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        };
        const { stripe } = window;
        const element = $(el);
        const attrs = helpers.getAttrs(el);
        const formEl = (element.is('form')) ? element : element.closest('form');
        const errorSelector = attrs.errorSelector || '#card-errors, .card-errors';
        const submitBtn = element.find('.stripe-submit-btn');
        const cardChangeEvent = (event) => {
          const displayError = element.find(errorSelector);
          formEl.data('changed', true);
          formEl.find('input[name=stripeToken]').remove();
          if (event.error) {
            displayError.text(event.error.message);
            submitBtn.addClass('disabled').prop('disabled', true);
          } else {
            displayError.text('');
            submitBtn.removeClass('disabled').prop('disabled', false);
          }
        };

        const stripeEls = stripe.elements();
        element.data('stripeEl', stripeEls);
        const crdEl = attrs.formSelector || '#card-element';
        const stripeCard = stripeEls.create('card', { style: stripeStyle });
        stripeCard.mount(crdEl);
        stripeCard.addEventListener('change', cardChangeEvent);
        const finalSubmit = () => {
          if (attrs.ajaxUrl) {
            const tmpFormData = formEl.serializeArray();
            const formData = {};
            helpers.forEach(tmpFormData, (itm) => {
              formData[itm.name] = itm.value;
            });
            Server.post(attrs.ajaxUrl, formData).then((data) => {
              if (data.status === 'failed') {
                if (data.errors.form !== undefined) {
                  const extraFields = (attrs.extraFormFields || '').split(',');
                  let currField;
                  for (let nr = 0; nr < extraFields.length; nr += 1) {
                    currField = extraFields[nr];
                    if (typeof data.errors.form[currField] !== 'undefined') {
                      element.find(`#${currField}_errors, .${currField}_errors`).html(data.errors.form[currField][0]);
                      element.find(`#${currField}_errors, .${currField}_errors`).show();
                    }
                  }
                } else if (data.errors.error !== undefined) {
                  element.find(errorSelector).text(data.errors.error.message);
                } else {
                  element.find(errorSelector).text('Processing error. Please try again later.');
                }
                element.find('.stripe-submit-btn').removeClass('disabled').prop('disabled', false);
                formEl.data('isNowSending', false);
                submitBtn.removeClass('btn-form-sending');
              } else if (data.status === 'OK') {
                if (attrs.successEvent) {
                  $rootScope.$emit(attrs.successEvent, { data, element, attrs });
                }
                if (attrs.successEval) {
                  vnode.context[attrs.successEval](data, element, attrs);
                }
                if (attrs.successUrl) {
                  window.location.replace(attrs.successUrl);
                }
              }
            });
          } else {
            formEl.submit();
          }
        };
        const stripeTokenHandler = (token) => {
          // Insert the token ID into the form so it gets submitted to the server
          formEl.find('input[name=stripeToken]').remove();
          formEl.append($('<input>').attr('type', 'hidden').attr('name', 'stripeToken').attr('value', token.id));
          formEl.data('changed', true);
        };

        submitBtn.off('click').on('click', function clickFn(evt) {
          const myself = $(this);
          if (myself.is('.disabled') || myself.data('disabled')) {
            evt.preventDefault();
            return false;
          }
          const myForm = myself.closest('form');
          if (myForm.length) {
            if (myForm.find(':invalid').length) {
              evt.preventDefault();
              return false;
            }
          }
          if (formEl.data('isNowSending')) {
            return false;
          }
          stripe.createToken(stripeCard).then((result) => {
            if (result.error) {
              // Inform the user if there was an error.
              element.find(errorSelector).text(result.error.message);
            } else {
              // Send the token to your server.
              stripeTokenHandler(result.token);
              formEl.data('isNowSending', true);
              myself.addClass('btn-form-sending');
              finalSubmit();
            }
          });
          evt.preventDefault();
          return false;
        });
      }
    };
    vnode.context.$nextTick(() => {
      fn(0);
    });
  },
};

PfVue.directive('stripeForm', stripeForm);

PfVue.directive('clickEvent', {
  inserted(el) {
    const element = $(el);
    const attrs = helpers.getAttrs(el);
    $(element).on('click', (event) => {
      $rootScope.$emit(attrs.clickEvent || 'clickEvent', { event });
    });
  },
});

PfVue.directive('setSparkline', {
  inserted(element, binding, vnode) { // binding, vnode
    const attrs = helpers.getAttrs(element);
    // helpers.doNothing(attrs, parse, vnode);
    element = $(element);
    let attrName;
    const opts = {
    };
    if (binding.value.functions) {
      helpers.forEach(binding.value.functions, (v, k) => {
        opts[k] = v;
      });
    }
    const spLineClasses = {};
    helpers.forEach(attrs, (attrVal2, attr) => {
      if (attr.indexOf('sparklineopt') === 0) {
        attrName = attr.substr(12);
        attrName = attrName[0].toLowerCase() + attrName.substr(1);
        opts[attrName] = attrs[attr];
      } else if (attr.indexOf('sparklineclass') === 0) {
        attrName = attr.substr(14);
        const clsName = helpers.toKebabCase(attrName);
        spLineClasses[clsName] = attrs[attr];
      } else if (attr.indexOf('sparklineevalopt') === 0) {
        attrName = attr.substr(16);
        attrName = attrName[0].toLowerCase() + attrName.substr(1);
        opts[attrName] = vnode.context.evaluate(attrs[attr]);
      } else if (attr.indexOf('sparklineevt') === 0) {
        attrName = attr.substr(12);
        attrName = attrName[0].toLowerCase() + attrName.substr(1);
        (function fn3(atr, attrName2, atrVal) {
          opts[attrName2] = function sprkCustFn(event, ui) {
            setTimeout(() => {
              $rootScope.$emit(atrVal, $(this), event, ui);
            }, 0);
          };
        }(attr, attrName, attrs[attr]));
      }
    });
    if (Object.keys(spLineClasses).length) {
      opts.classes = spLineClasses;
    }
    let elSparkline = element;
    if (attrs.setSparkline) {
      elSparkline = elSparkline.find(attrs.setSparkline);
    }
    elSparkline.data('sparkline__opts', opts);
    elSparkline.sparkline(binding.value.data, opts);
  },
});

PfVue.directive('groupMoreMenu', {
  inserted(element, binding) { // binding, vnode
    const srcEl = $(element);
    const targetId = binding.value;
    const newEl = $('<a>').attr('href', srcEl.attr('href')).addClass('dropdown-item').text(srcEl.text());
    const parent = srcEl.closest('[active-if-url]');
    let setMoreToActive = false;
    if (parent && parent.length) {
      const activeIfUrl = parent.attr('active-if-url');
      const rgx = new RegExp(activeIfUrl);
      let fn = 'removeClass';
      if (window.location.pathname.match(rgx)) {
        fn = 'addClass';
        setMoreToActive = true;
      }
      newEl[fn]('active');
    }
    const targetEl = $('#' + targetId);
    if (setMoreToActive) {
      targetEl.closest('[more-container]').addClass('active');
    }
    targetEl.append(newEl);
  },
});

PfVue.directive('countdownTime', {
  inserted(element, binding) { // binding, vnode
    const now = (new Date()).getTime() / 1000;
    $(element).data('startTime', now);
    $(element).data('seconds', binding.value.time);
    setInterval(() => {
      const secLeft = parseInt(((new Date()).getTime() / 1000) - parseInt($(element).data('startTime'), 10), 10);
      let secsToEnd = Math.max(0, parseInt($(element).data('seconds'), 10) - secLeft);
      let txt = '';
      const days = parseInt(secsToEnd / (3600 * 24), 10);
      if (days) {
        if (days > 1) {
          txt = `${days} days`;
        } else {
          txt = '1 day';
        }
      } else if (secsToEnd) {
        const hours = parseInt(secsToEnd / 3600, 10);
        secsToEnd -= hours * 3600;
        let mins = parseInt(secsToEnd / 60, 10);
        let secs = secsToEnd % 60;
        mins = (mins < 10) ? `0${mins}` : mins;
        secs = (secs < 10) ? `0${secs}` : secs;
        txt = `${hours}h ${mins}m ${secs}s`;
      } else {
        txt = 'Time elapsed';
      }
      $(element).text(txt);
    }, 500);
  },
});

PfVue.directive('setDateRange', {
  inserted(element, binding, vnode) { // binding, vnode
    const attrs = helpers.getAttrs(element);
    // helpers.doNothing(attrs, parse, vnode);
    element = $(element);
    let attrName;
    const opts = binding.value || {
    };
    const dtRngClasses = {};
    const eventName = attrs.dateRangeEvent || 'dateRangeEvent';
    helpers.forEach(attrs, (attrVal2, attr) => {
      if (attr.indexOf('daterangeopt') === 0) {
        attrName = attr.substr(12);
        attrName = attrName[0].toLowerCase() + attrName.substr(1);
        opts[attrName] = attrs[attr];
      } else if (attr.indexOf('daterangeclass') === 0) {
        attrName = attr.substr(14);
        const clsName = helpers.toKebabCase(attrName);
        dtRngClasses[clsName] = attrs[attr];
      } else if (attr.indexOf('daterangeevalopt') === 0) {
        attrName = attr.substr(16);
        attrName = attrName[0].toLowerCase() + attrName.substr(1);
        opts[attrName] = vnode.context.evaluate(attrs[attr]);
      } else if (attr.indexOf('daterangebool') === 0) {
        attrName = attr.substr(13);
        attrName = attrName[0].toLowerCase() + attrName.substr(1);
        opts[attrName] = !!attrs[attr];
      } else if (attr.indexOf('daterangeevt') === 0) {
        attrName = attr.substr(12);
        attrName = attrName[0].toLowerCase() + attrName.substr(1);
        (function fn3(atr, attrName2, atrVal) {
          opts[attrName2] = function dtRngCustFn(event, ui) {
            setTimeout(() => {
              $rootScope.$emit(atrVal, $(this), event, ui);
            }, 0);
          };
        }(attr, attrName, attrs[attr]));
      }
    });
    if (Object.keys(dtRngClasses).length) {
      opts.classes = dtRngClasses;
    }
    let elDateRange = element;
    if (attrs.setDateRange) {
      elDateRange = elDateRange.find(attrs.setDateRange);
    }
    elDateRange.data('daterange__opts', opts);
    elDateRange.daterangepicker(opts, (start, end, label) => {
      $rootScope.$emit(eventName, {
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),
        label,
        rawStart: start,
        rawEnd: end,
      });
    });
  },
});

PfVue.filter('amount', (value) => '$' + parseFloat(value).toFixed(2));

PfVue.filter('truncate', (value, maxChars = 15) => {
  if (value && typeof value === 'string' && value.length > maxChars) {
    return `${value.substr(0, maxChars)}...`;
  }
  return value;
});

PfVue.filter('unsafe', (val) => val);

PfVue.filter('debugLog', (val) => {
  // not used, but needed sometimes for debugging purpose
  helpers.consoleLog(val);
  return val;
});

PfVue.filter('stripprotocol', (val) => val.replace(/^https?:/i, ''));

module.export = {};
// export default {};
