<template>
  <div
      v-if="pages.length"
      class="tw-min-w-14"
  >
    <h5 class="tw-mb-2">Recommended content</h5>
    <div
        class="tw-relative tw-z-0 tw-pl-4"
    >
      <span
          v-if="!isMobileDev"
          class="tw-absolute tw-top-1/3 tw-left-0 tw-z-20 tw-h-8 tw-w-4 tw-bg-black tw-shadow-md
                 tw-inline-flex tw-items-center tw-justify-center tw-pointer-events-auto hover:tw-opacity-80"
          :class="{
              'tw-invisible': isPrevDisabled,
              'tw-cursor-pointer': !isPrevDisabled
            }"
          @click="prev"
      >
          <b-icon class="tw-h-5 tw-w-5 tw-text-white" icon="chevron-compact-left"/>
        </span>
      <span
          v-if="!isMobileDev"
          class="tw-absolute tw-top-1/3 tw-left-full tw-z-20 tw-bg-black tw-h-8 tw-w-4 tw-shadow-md tw-inline-flex
               tw-items-center tw-justify-center tw-pointer-events-auto tw-text-white hover:tw-opacity-80"
          :class="{
            'tw-invisible': isNextDisabled,
            'tw-cursor-pointer': !isNextDisabled
          }"
          @click="next"
      >
        <b-icon class="tw-h-5 tw-w-5" icon="chevron-compact-right"/>
      </span>
      <swiper
          ref="swiper"
          v-bind="swiperOptions"
          :class="{
            'swiper-no-swiping': swiperOptions.noSwiping,
            '!tw-overflow-hidden': !dropdownOpened
          }"
          @swiper="
            (instance) => {
              swiper = instance;
            }
          "
      >
        <swiper-slide
            v-for="[index, course] of courses.entries()"
            :key="`related-course-${index}`"
            class="slide"
        >
          <course-preview
            :course="course"
          />
        </swiper-slide>
        <swiper-slide
            v-for="[index, page] of pages.entries()"
            :key="`related-page-${index}`"
            class="slide"
        >
          <most-viewed-video-card
              :ref="`page-` + page.id"
              class="tw-text-wrap"
              :pageUrl="page.url"
              :title="page.title"
              :description="page.description"
              :thumbnailUrl="page.thumbnail"
              :previewUrl="page.preview_url"
              :videoId="page.preview && page.preview.id"
              :videoHost="page.preview && page.preview.host"
              :videoDuration="page.video_duration_minutes"
              :userAuthenticated="$root.isAuthenticated"
              :isWatched="page.is_watched "
              :showPreview="page.preview && currentPlayingPreviewVideoId === page.preview.id"
              :order="page.sort_order"
              :page-id="page.id"
              :publish-date="page.publish_date"
              :iframe-id="page.preview && _getVideoPreviewIframeId(page)"
              :watched-percent="page.watched_percent"
              @mouse-on-thumbnail="(videoId) => mouseOnVideoThumbnail(videoId, _getVideoPreviewIframeId(page))"
              @mouse-out-thumbnail="mouseOutVideoThumbnail"
              @dropdownToggled="dropdownState => dropdownOpened = dropdownState"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import MostViewedVideoCard from './MostViewedVideoCard.vue';
import VideoPreviewMixin from '../mixins/VideoPreviewMixin.vue';
import { appHelper as helper, Server } from '../utils/helpers.js';
import { BIcon } from 'bootstrap-vue';
import SwiperMixin from '../mixins/SwiperMixin.vue';
import { Swiper, SwiperSlide } from 'swiper-vue2';
import { VIDEO_HOSTS } from '../utils/constants.js';
import CoursePreview from './CoursePreview.vue'

export default {
  name: 'RelatedContent',
  components: {
    MostViewedVideoCard,
    BIcon,
    Swiper,
    SwiperSlide,
    CoursePreview
  },
  mixins: [VideoPreviewMixin, SwiperMixin],
  props: {
    targetPageId: {
      type: Number,
      required: true,
    },
  },
  emits: [],
  data() {
    return {
      pages: [],
      courses: [],
      dropdownOpened: false,
    };
  },
  async created() {
    await this.loadRelatedContent();
    for (const page of this.pages) {
      const iframeId = this._getVideoPreviewIframeId(page);
      if (page.preview && page.preview.id && page.preview.host === VIDEO_HOSTS.YOUTUBE) this.loadPreview(iframeId);
    }
  },
  methods: {
    async loadRelatedContent() {
      const url = `/api/pages/${this.targetPageId}/related/content/`;
      const response = await Server.get(url);
      this.courses = response.courses;
      this.pages = response.pages;

      return response;
    },
    _getVideoPreviewIframeId(page) {
      return 'related-page-preview' + page.preview.id;
    },
  },
  computed: {
    isMobileDev() {
      return helper.isMobileDev();
    },
  },
  watch: {},
};
</script>
