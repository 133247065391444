var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pages.length)?_c('div',[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"tw-relative tw-pl-4"},[(!_vm.isMobileDev)?_c('span',{staticClass:"tw-absolute tw-top-1/3 tw-left-0 tw-z-20 tw-h-8 tw-w-4 tw-bg-black tw-shadow-md tw-inline-flex tw-items-center tw-justify-center tw-pointer-events-auto hover:tw-opacity-80",class:{
            'tw-invisible': _vm.isPrevDisabled,
            'tw-cursor-pointer': !_vm.isPrevDisabled
          },on:{"click":_vm.prev}},[_c('b-icon',{staticClass:"tw-h-5 tw-w-5 tw-text-white",attrs:{"icon":"chevron-compact-left"}})],1):_vm._e(),_vm._v(" "),(!_vm.isMobileDev)?_c('span',{staticClass:"tw-absolute tw-top-1/3 tw-left-full tw-z-20 tw-bg-black tw-h-8 tw-w-4 tw-shadow-md tw-inline-flex tw-items-center tw-justify-center tw-pointer-events-auto tw-text-white hover:tw-opacity-80",class:{
          'tw-invisible': _vm.isNextDisabled,
          'tw-cursor-pointer': !_vm.isNextDisabled
        },on:{"click":_vm.next}},[_c('b-icon',{staticClass:"tw-h-5 tw-w-5",attrs:{"icon":"chevron-compact-right"}})],1):_vm._e(),_vm._v(" "),_c('swiper',_vm._b({ref:"swiper",class:{
          'swiper-no-swiping': _vm.swiperOptions.noSwiping,
          '!tw-overflow-hidden': !_vm.dropdownOpened
        },on:{"swiper":(instance) => {
            _vm.swiper = instance;
          }}},'swiper',_vm.swiperOptions,false),_vm._l((_vm.pages.entries()),function([index, page]){return _c('swiper-slide',{key:index,staticClass:"slide"},[(page.preview)?_c('most-viewed-video-card',{ref:`page-` + page.id,refInFor:true,staticClass:"tw-text-wrap",attrs:{"pageUrl":page.url,"title":page.title,"description":page.description,"thumbnailUrl":page.thumbnail,"previewUrl":page.preview_url,"videoId":page.preview.id,"videoHost":page.preview.host,"videoDuration":page.video_duration_minutes,"userAuthenticated":_vm.$root.isAuthenticated,"isWatched":page.is_watched,"watchedPercent":page.watched_percent,"show-progress":"","showPreview":_vm.currentPlayingPreviewVideoId === page.preview.id,"order":page.sort_order,"page-id":page.id,"publish-date":page.publish_date,"iframe-id":_vm._getVideoPreviewIframeId(page)},on:{"mouse-on-thumbnail":(videoId) => _vm.mouseOnVideoThumbnail(videoId, _vm._getVideoPreviewIframeId(page)),"mouse-out-thumbnail":_vm.mouseOutVideoThumbnail,"dropdownToggled":dropdownState => _vm.dropdownOpened = dropdownState}}):_vm._e()],1)}),1)],1)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-mb-4"},[_c('h5',{staticClass:"tw-w-max tw-mb-0"},[_vm._v("Viewing history")]),_vm._v(" "),_c('a',{staticClass:"tw-ml-2 tw-self-start tw-text-sm tw-text-black hover:tw-no-underline hover:tw-opacity-70 hover:tw-text-black",attrs:{"href":"/history"}},[_vm._v("view all")])])
}]

export { render, staticRenderFns }