var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-relative"},[(_vm.showUnmuteButton)?_c('button',{staticClass:"tw-border-0 tw-px-4 tw-py-2 tw-absolute tw-z-10 tw-left-1/2 -tw-translate-x-1/2 tw-bg-black tw-rounded-[30px] tw-text-white",on:{"click":_vm.unmutePlayer}},[_vm._v("\n    Unmute\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"!tw-static embed-responsive embed-responsive-16by9 vimeo preview-video mb-2 tw-rounded-none sm:tw-rounded-2xl"},[(_vm.page.main_video_url || _vm.page.preview_url)?_c('div',[(_vm.page.main_video_url)?_c('iframe',{staticClass:"embed-responsive-item",class:{
            'tw-hidden': !_vm.playerReady
          },attrs:{"id":"id-preview-video-frame","src":_vm.mainVideoUrl,"width":"400","height":"225","webkitAllowFullScreen":"","mozallowfullscreen":"","allowFullScreen":"","video-host":_vm.page.video.host === _vm.VIDEO_HOSTS.VIMEO ? 'vimeo' : 'youtube',"video-events-event-prefix":_vm.page.video.host === _vm.VIDEO_HOSTS.VIMEO ? 'vimeoEvent_' : 'YtVideo_'}}):(_vm.page.preview_url)?_c('div',[_c('iframe',{staticClass:"embed-responsive-item",class:{
              'tw-hidden': !_vm.playerReady
            },attrs:{"width":"400","height":"225","frameborder":"0","id":"id-preview-video-frame","webkitAllowFullScreen":"","mozallowfullscreen":"","allowFullScreen":"","src":_vm.previewUrl,"video-host":_vm.page.video.host === _vm.VIDEO_HOSTS.VIMEO ? 'vimeo' : 'youtube',"video-events-event-prefix":_vm.page.video.host === _vm.VIDEO_HOSTS.VIMEO ? 'vimeoEvent_' : 'YtVideo_'}}),_vm._v(" "),_c('div',{staticClass:"overlay-box"},[_c('div',{staticClass:"centering"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content"},[(_vm.isForCourse)?_c('div',[(_vm.$root.isAuthenticated || _vm.showFullVideo)?_c('div',[_c('h6',{staticClass:"my-2"},[(!_vm.$root.isAuthenticated)?_c('a',{staticClass:"text-decoration-underline",attrs:{"href":"/accounts/join/annual_19700"}},[_vm._v("Join Golf Smart\n                        Academy")]):_c('a',{staticClass:"text-decoration-underline",attrs:{"href":"accounts/checkout/annual_19700"}},[_vm._v("Join Golf Smart\n                        Academy")])]),_vm._v(" "),_c('h6',[_vm._v("\n                      Get unlimited access to 1100+ videos, courses, and more.\n                    ")])]):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.$root.isAuthenticated)?_c('div',[_c('h5',[_c('a',{staticClass:"text-decoration-underline",attrs:{"href":"#"},on:{"click":function($event){return _vm.$root.showModal('signup')}}},[_vm._v("Click here")]),_vm._v("\n                    and\n                    enter\n                    your\n                    email address to watch the full video\n                  ")])]):(!_vm.showFullVideo)?_c('div',[_vm._m(0),_vm._v(" "),_c('h5',[_vm._v("\n                    For instant access to 1100+ instructional videos\n                  ")])]):_vm._e()])])])])]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.page.thumbnail)?_c('div',{staticClass:"embed-responsive-item",class:{
          'tw-hidden': _vm.playerReady
        }},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.page.thumbnail}})]):_c('div',{staticClass:"embed-responsive-item",class:{
          'tw-hidden': _vm.playerReady
        }},[_c('img',{staticClass:"w-100",attrs:{"src":"/static/img/gsa-instruction-default-1600x900.jpg"}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('a',{staticClass:"text-decoration-underline",attrs:{"href":"/subscriptions"}},[_vm._v("Join now")])])
}]

export { render, staticRenderFns }