<template>
  <a
      :href="course.url"
      class="tw-basis-full md:tw-basis-[45%] lg:tw-basis-[30%]"
  >
    <div class="tw-relative">
      <img class="tw-rounded-lg" :src="course.thumbnail">
      <div
          class="tw-z-10 tw-flex tw-justify-between tw-absolute tw-top-0 tw-px-1 tw-pt-0.5 tw-w-full
                 tw-text-white tw-font-normal tw-leading-[19px]"
      >
      <span
          class="
            tw-flex tw-items-center tw-gap-2 tw-py-1 tw-px-2
            tw-bg-white tw-bg-opacity-20 tw-text-white tw-rounded-[30px] tw-font-semibold tw-leading-[19px]
          "
          v-tippy
          :content="_getTippyContent(course)"
      >
          <img class="tw-h-4 tw-w-4" src="/static/img/camera.png">
          <span v-if="course.watched_lesson_count === 0">
              {{ course.lesson_count }} lessons
          </span>
          <span v-else>
              {{ course.watched_lesson_count }} / {{ course.lesson_count }}
          </span>
      </span>
        <span
            v-if="course.is_premium"
            class="
                tw-py-1 tw-px-3 tw-bg-yellow-400 tw-rounded-[30px]
            "
            v-tippy
            content="Premium"
        >
          <img
              src="/static/img/crown.svg"
              class="tw-h-5 tw-w-5"
          >
        </span>
      </div>
      <div class="tw-w-full tw-h-full tw-absolute tw-top-0 tw-bg-black tw-bg-opacity-20">
        <h6
            class="tw-absolute tw-mb-0 tw-bottom-0 tw-p-1.5 tw-basis-[100px] tw-text-white tw-text-wrap tw-font-normal tw-text-sm
                   md:tw-text-lg center tw-grow tw-line-clamp-2 tw-text-ellipsis
                   tw-w-full tw-text-center"
        >
          <span>{{ course.name }}</span>
        </h6>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'CoursePreview',
  components: {},
  mixins: [],
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  setup() {},
  created() {},
  methods: {
    _getTippyContent(course) {
      if (course.watched_lesson_count === course.lesson_count) return 'Completed';
      if (course.watched_lesson_count > 0) return 'In progress';
      if (course.watched_lesson_count === 0 && !course.is_public && course.is_user_allowed) return 'Purchased';
      if (course.watched_lesson_count === 0 && !course.is_public && !course.is_user_allowed) return 'Not purchased';
      if (course.watched_lesson_count === 0 && course.is_public) return 'Public course';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
