<template>
  <div
      class="tw-relative"
  >
    <button
        v-if="showUnmuteButton"
        @click="unmutePlayer"
        class="tw-border-0 tw-px-4 tw-py-2 tw-absolute tw-z-10 tw-left-1/2 -tw-translate-x-1/2 tw-bg-black tw-rounded-[30px] tw-text-white"
    >
      Unmute
    </button>
    <div
        class="!tw-static embed-responsive embed-responsive-16by9 vimeo preview-video mb-2 tw-rounded-none sm:tw-rounded-2xl"
    >
      <div v-if="page.main_video_url || page.preview_url">
        <iframe
            v-if="page.main_video_url"
            id="id-preview-video-frame"
            class="embed-responsive-item"
            :class="{
              'tw-hidden': !playerReady
            }"
            :src="mainVideoUrl"
            width="400"
            height="225"
            webkitAllowFullScreen mozallowfullscreen allowFullScreen
            :video-host="page.video.host === VIDEO_HOSTS.VIMEO ? 'vimeo' : 'youtube'"
            :video-events-event-prefix="page.video.host === VIDEO_HOSTS.VIMEO ? 'vimeoEvent_' : 'YtVideo_'"
        ></iframe>
        <div
            v-else-if="page.preview_url"
        >
          <iframe
              class="embed-responsive-item"
              width="400"
              height="225"
              frameborder="0"
              id="id-preview-video-frame"
              :class="{
                'tw-hidden': !playerReady
              }"
              webkitAllowFullScreen mozallowfullscreen allowFullScreen
              :src="previewUrl"
              :video-host="page.video.host === VIDEO_HOSTS.VIMEO ? 'vimeo' : 'youtube'"
              :video-events-event-prefix="page.video.host === VIDEO_HOSTS.VIMEO ? 'vimeoEvent_' : 'YtVideo_'"
          >
          </iframe>
          <div class="overlay-box">
            <div class="centering">
              <div class="wrapper">
                <div class="content">
                  <div v-if="isForCourse">
                    <div v-if="$root.isAuthenticated || showFullVideo">
                      <h6 class="my-2">
                        <a
                            v-if="!$root.isAuthenticated"
                            href="/accounts/join/annual_19700"
                            class="text-decoration-underline"
                        >Join Golf Smart
                          Academy</a>
                        <a
                            v-else
                            href="accounts/checkout/annual_19700"
                            class="text-decoration-underline"
                        >Join Golf Smart
                          Academy</a>
                      </h6>
                      <h6>
                        Get unlimited access to 1100+ videos, courses, and more.
                      </h6>
                    </div>
                  </div>
                  <div v-if="!$root.isAuthenticated">
                    <h5><a @click="$root.showModal('signup')" href="#" class="text-decoration-underline">Click here</a>
                      and
                      enter
                      your
                      email address to watch the full video
                    </h5>
                  </div>
                  <div v-else-if="!showFullVideo">
                    <h4>
                      <a href="/subscriptions" class="text-decoration-underline">Join now</a>
                    </h4>
                    <h5>
                      For instant access to 1100+ instructional videos
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
          v-if="page.thumbnail"
          class="embed-responsive-item"
          :class="{
            'tw-hidden': playerReady
          }"
      >
        <img class="w-100" :src="page.thumbnail"/>
      </div>
      <div
          v-else
          class="embed-responsive-item"
          :class="{
            'tw-hidden': playerReady
          }"
      >
        <img class="w-100" src="/static/img/gsa-instruction-default-1600x900.jpg"/>
      </div>
    </div>
  </div>
</template>

<script>
import { $rootScope } from '../utils/base.js'
import VideoPlayerFacade from '../utils/VideoPlayerFacade.js'
import { appHelper as helpers } from '../utils/helpers.js'
import { VIDEO_HOSTS } from '../utils/constants.js'

export default {
  name: 'VideoPlayer',
  components: {},
  mixins: [],
  props: {
    page: {
      type: Object,
      required: true,
    },
    showFullVideo: {
      type: Boolean,
      required: true,
    },
  },
  emits: [],
  data () {
    return {
      player: null,
      showUnmuteButton: false,
      playerReady: false,
    }
  },
  watch: {},
  setup () {},
  mounted () {
    const playerIframeId = 'id-preview-video-frame'
    const playerIframe = document.getElementById(playerIframeId)
    const attrs = helpers.getAttrs(playerIframe)
    $rootScope.$on('player_ready', (iframeId) => {
      this.playerReady = true
      if (playerIframeId === iframeId) {
        if (window.video_seconds_watched) {
          const secondsWatched = parseInt(window.video_seconds_watched, 10)
          this.player.setCurrentTime(secondsWatched)
        }
        this.player.pause()
        this.player.unmute()
        setInterval(() => { this._setUnmuteButtonStatus() }, 5000)
      }
    })
    this.player = new VideoPlayerFacade(attrs.videoHost, attrs.id)
    window.currentPlayer = this.player
  },
  methods: {
    unmutePlayer () {
      this.player.unmute()
      this.showUnmuteButton = false
    },
    async _setUnmuteButtonStatus () {
      this.showUnmuteButton = this.player
          && await this.player.getMutedState()
          && !this.player.isEnded
    },
  },
  computed: {
    VIDEO_HOSTS () {
      return VIDEO_HOSTS
    },
    videoQuality () {
      return helpers.isMobileDev() ? 'quality=360p' : 'quality=720p'
    },
    previewUrl () {
      return this.page.preview_url + `?enablejsapi=1&rel=0&api=1&autoplay=0&muted=0&mute=0&autopause=0&${this.videoQuality}&`
    },
    mainVideoUrl () {
      return this.page.main_video_url + `?enablejsapi=1&rel=0&api=1&autoplay=0&muted=0&mute=0&autopause=0&quality=${this.videoQuality}&`
    },
    isForCourse () {
      return window.location.href.includes('/courses')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
