<script>
import { appHelper as helper } from '../utils/helpers.js';
import 'swiper/swiper-bundle.css';

export default {
  name: 'SwiperMixin',
  data() {
    return {
      swiper: null,
      swiperHasNextPage: false,
      swiperOptions: {
        resizeObserver: true,
        noSwiping: !helper.isMobileDev(),
        spaceBetween: 10,
        breakpoints: {
          320: {
            slidesPerView: 2.2,
          },
          560: {
            slidesPerView: 2.2,
          },
          767: {
            slidesPerView: 2.2,
          },
          992: {
            slidesPerView: 3.2,
          },
          1101: {
            slidesPerView: 4,
          },
        },
      },
      isPrevDisabled: true,
      isNextDisabled: false,
    };
  },
  methods: {
    prev() {
      this.swiper.slidePrev();
      this.checkDisabledSliderControls();
    },
    next() {
      this.swiper.slideNext();
      this.checkDisabledSliderControls();
    },
    checkDisabledSliderControls() {
      if (!this.swiper.isEnd && !this.swiper.isBeginning) {
        this.isNextDisabled = false;
        this.isPrevDisabled = false;
      } else if (this.swiper.isEnd) {
        if (!this.swiperHasNextPage) this.isNextDisabled = true;
      } else {
        this.isPrevDisabled = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// The code below needed to fix overflow-y: hidden for swiper.js
::v-deep .swiper-container {
  overflow: visible;
  z-index: 20;
}

::v-deep .swiper-wrapper {
  z-index: 10;
}

::v-deep .swiper-slide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
}

::v-deep .swiper-slide-active {
  opacity: 1;
  visibility: visible;
}

::v-deep .swiper-slide-active + .swiper-slide {
  opacity: 1;
  visibility: visible;
}

::v-deep .swiper-slide-active + .swiper-slide + .swiper-slide {
  opacity: 1;
  visibility: visible;
}

::v-deep .swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide {
  opacity: 1;
  visibility: visible;
}

</style>
