import * as helpers from '&/utils/helpers';
import * as PfBase from '&/utils/base';
import CtrlMain from '&/controllers/CtrlMain.js';
import jquery from 'jquery';
import VideoPlayerFacade from '../utils/VideoPlayerFacade.js';

const $ = jquery;
const { $rootScope } = PfBase;

const hlp = helpers.appHelper;
const { Server, Deferred } = helpers;

(() => {})(hlp.consoleLog, $rootScope, $, Server, Deferred);

const CtrlCourses = {
  name: 'ctrlCourses',
  el: '.ctrl-course-controller',
  components: {
  },
  mixins: [CtrlMain],
  data() {
    return {
      courseTabIdx: 1,
      lesson: false,
      videoState: 'pause',
      player: null,
    };
  },
  created() {
    const logPlayLesson = () => {
      if (this.lesson) {
        this.markWatched(this.lesson);
        this.videoState = 'play';
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    };
    $rootScope.$on('courseResized', (evt) => {
      if (evt.enlarged) {
        if (hlp.testMinWidth(993)) {
          if (this.courseTabIdx === 0) {
            this.courseTabIdx = 1;
          }
        }
      } else if (hlp.testMaxWidth(992)) {
        if (this.courseTabIdx === 1) {
          this.courseTabIdx = 0;
        }
      }
    });
    $rootScope.$on('vimeoEvent_pause', () => {
      if (this.lesson) {
        this.videoState = 'pause';
      }
    });
    $rootScope.$on('vimeoEvent_ended', () => {
      if (this.lesson) {
        this.videoState = 'ended';
      }
      $('body').addClass('overlay-mode');
    });
    $rootScope.$on('YtVideo_ended', () => {
      $('body').addClass('overlay-mode');
      if (this.lesson) {
        this.videoState = 'ended';
      }
    });
    // eslint-disable-next-line no-unused-vars
    $rootScope.$on('vimeoEvent_play', (evt) => {
      $('body').removeClass('overlay-mode');
      logPlayLesson();
    });
    $rootScope.$on('YtVideo_play', () => {
      logPlayLesson();
      $('body').removeClass('overlay-mode');
    });
  },
  mounted() {
    const playerIframeId = 'id-preview-video-frame';
    const playerIframe = document.getElementById(playerIframeId);
    const attrs = hlp.getAttrs(playerIframe);
    if (attrs.videoHost === 'youtube') {
      // eslint-disable-next-line no-unused-vars
      $rootScope.$on('player_ready', (iframeId) => {
        this.player.play();
      });
    }
    this.player = new VideoPlayerFacade(attrs.videoHost, attrs.id);
    if (attrs.videoHost !== 'youtube') {
      this.player.play();
    }
    window.currentPlayer = this.player;
  },
  methods: {
    setLesson(lesson) {
      this.lesson = lesson;
    },
    markWatched(lesson) {
      Server.post(`courses/log/${lesson}/`).then(() => {});
    },
    buyCourse(courseId) {
      if (this.$root.isAuthenticated) {
        window.location.href = `/accounts/checkout/${courseId}`;
      } else {
        window.location.href = `/accounts/join/${courseId}`;
      }
    },
  },
};
export default CtrlCourses;
