<template>
  <div>
    <div class="tw-flex tw-items-center">
      <input
          class="form-control w-100 search-input pr-5"
          type="text"
          :value="searchString"
          v-debounce:1500ms="triggerSearch"
          placeholder="search videos by title..."
          aria-label="Search"
      >
      <b-icon
          class="tw-cursor-pointer tw-text-red-600 tw-h-5 tw-w-5 tw-absolute tw-right-2"
          icon="x"
          @click="resetSearch"
      ></b-icon>
    </div>

    <treeselect
        id="treeselect"
        class="mt-3"
        ref="treeselect"
        v-model="selectedTags"
        :flat="true"
        :multiple="true"
        :maxHeight="'max-content'"
        :zIndex="1"
        :alwaysOpen="true"
        :disableBranchNodes="false"
        :options="options"
        :default-options="true"
        :async="true"
        :load-options="search"
        :beforeClearAll="resetSearch"
        @select="expandNode"
        @deselect="expandNode"
    >
      <label slot="option-label" slot-scope="{ node }"
             class="tw-group tw-m-0 tw-w-full"
             :ref="'tag-' + node.id"
      >
        <div
            class="tw-flex tw-items-center tw-py-4 tw-pr-1 tw-text-nowrap hover:tw-bg-zinc-100"
            :class="{
               'tw-pl-1':  getNodeLevel(node.id) === 0,
               'tw-pl-5':  getNodeLevel(node.id) === 1,
               'tw-pl-8':  getNodeLevel(node.id) === 2,
               'tw-pl-10': getNodeLevel(node.id) === 3,
               'tw-pl-12': getNodeLevel(node.id) === 4,
               'tw-pl-14': getNodeLevel(node.id) === 5,
             }"
        >
          <div class="tw-inline-flex tw-items-center tw-mr-1">
            <b-icon
                v-show="(node.isBranch && !node.isExpanded) || (!node.isBranch && !selectedTags.includes(node.id))"
                icon="plus-lg"
                class="tw-h-3 tw-w-3"
            ></b-icon>
            <b-icon
                v-show="(node.isBranch && node.isExpanded) || (!node.isBranch && selectedTags.includes(node.id))"
                icon="dash-lg"
                class="tw-h-3 tw-w-3"
            ></b-icon>
          </div>
          <h3
              class="tw-m-0 tw-text-sm md:tw-text-base tw-inline tw-overflow-hidden tw-text-ellipsis"
              v-tippy="{ delay: 1000 }"
              :content="node.label"
          >
            {{ node.label }}
          </h3>
          <span
              class="ml-auto tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-bg-zinc-100 tw-rounded-full tw-ml-3"
              v-if="!searchInProgress"
          >
            <span v-if="userAuthenticated">
              {{ node.raw.watched }} / {{ node.raw.total }}
            </span>
            <span v-else-if="!userAuthenticated">
              {{ node.raw.total }}
            </span>
          </span>
        </div>
        <div
            v-if="!node.isBranch && selectedTags.includes(node.id) || (node.isBranch && node.isExpanded && videos[node.id] && videos[node.id].videos.length !== 0)"
            @mousedown.stop="() => {}"
        >
          <div class="tw-flex tw-flex-wrap tw-gap-x-6 tw-gap-y-8">
            <recommended-video-card
                v-if="page.preview"
                :ref="`page-` + page.id"
                :iframe-id="_getVideoPreviewIframeId(page)"
                class="tw-text-wrap"
                v-for="[index, page] of videos[node.id].videos.entries()"
                :key="index"
                :pageUrl="page.url + `?selected_tag=${node.id}`"
                :title="page.title"
                :description="page.description"
                :thumbnailUrl="page.thumbnail"
                :previewUrl="page.preview_url"
                :videoId="page.preview.id"
                :videoHost="page.preview.host"
                :videoDuration="page.video_duration_minutes"
                :userAuthenticated="userAuthenticated"
                :isWatched="page.is_watched "
                :watchedPercent="page.watched_percent"
                :showPreview="currentPlayingPreviewVideoId === page.preview.id"
                :order="searchString !== '' ? null : index + 1"
                :isHighlighted="videoPageId && videoPageId === page.id"
                :page-id="page.id"
                :publish-date="page.publish_date"
                @mouse-on-thumbnail="(videoId) => mouseOnVideoThumbnail(videoId, _getVideoPreviewIframeId(page))"
                @mouse-out-thumbnail="mouseOutVideoThumbnail"
            />
          </div>
          <div v-if="loadingInProgress && lastSelectedTag === node.id">Loading videos...</div>
          <div
              v-else-if="videos[node.id].hasNextPage"
              @click="getVideos([node.id], videos[node.id].page + 1)"
              class="tw-mt-3 tw-flex tw-justify-center tw-text-blue-1"
          >
            <span>Load more</span>
          </div>
          <div
              v-if="videos[node.id].videos.length === 0 && !loadingInProgress"
              class="tw-flex tw-justify-center"
          >
            <span>No videos found</span>
          </div>
        </div>
      </label>
    </treeselect>
  </div>
</template>

<script>
import { Server } from '../utils/helpers.js';
import RecommendedVideoCard from './RecommendedVideoCard.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';

import { BIcon } from 'bootstrap-vue';
import { noop } from 'lodash';
import Mark from 'mark.js';
import VideoPreviewMixin from '../mixins/VideoPreviewMixin.vue';

export default {
  name: 'VideoTreeselect',
  props: {
    videoPageId: {
      type: Number,
      default: null,
    },
    selectedTagId: {
      type: Number,
      default: null,
    },
  },
  components: {
    Treeselect, RecommendedVideoCard, BIcon,
  },
  mixins: [VideoPreviewMixin],
  data() {
    return {
      selectedTags: [],
      lastSelectedTag: null,
      userAuthenticated: null,
      options: null,
      loadedVideos: {
        // 46: {
        //   videos: [],
        //   lastPage: 1,
        //   hasNextPage: true,
        //   watched: 0,
        // },
      },
      searchVideos: {},
      searchString: '',
      loadingInProgress: false,
      highlightInstance: null,
    };
  },
  async mounted() {
    setTimeout(() => {
      $('.vue-treeselect__menu')[0].style.position = 'static';
      $('.vue-treeselect__menu-container')[0].style.position = 'static';
    }, 100);

    if (!this.selectedTagId) {
      await this.getVideoFilters();
      await this.getVideos([46], 1);
    } else {
      await this.getVideoFilters();

      await this.getVideos([this.selectedTagId], 1);
      let currentVideoFound = this.videos[this.selectedTagId].videos.some((page) => page.id === this.videoPageId);

      while (!currentVideoFound) {
        await this.getVideos([this.selectedTagId], this.videos[this.selectedTagId].page + 1);
        if (this.videos[this.selectedTagId].videos.some((page) => page.id === this.videoPageId)) currentVideoFound = true;
      }
      if (currentVideoFound) {
        this.selectedTags = [parseInt(this.selectedTagId, 10)];
        this.lastSelectedTag = parseInt(this.selectedTagId, 10);
        this.expandNodeBySelectedItem();
        setTimeout(() => {
          const { offsetTop } = this.$refs['page-' + this.videoPageId][0].$el;
          $('#sidebar').scrollTop(offsetTop);
        }, 100);
      }
    }
    const treeeselectElement = document.getElementById('treeselect');
    this.highlightInstance = new Mark(treeeselectElement);
  },
  methods: {
    noop,
    async triggerSearch(searchString) {
      this.searchString = searchString;

      if (searchString === '') {
        this.resetSearch();
      } else {
        Object.keys(this.$refs.treeselect.remoteSearch).forEach((key) => {
          if (key !== '') {
            delete this.$refs.treeselect.remoteSearch[key];
          }
        });
      }
      this.$refs.treeselect.trigger.searchQuery = searchString;
    },
    extractIds(data, result = []) {
      for (const item of data) {
        result.push(parseInt(item.id, 10));
        if (item.children && item.children.length > 0) {
          this.extractIds(item.children, result);
        }
      }
      return result;
    },
    async search({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        if (!this.options && searchQuery === '') {
          const response = await this.getVideoFilters();
          callback(null, response);
        } else if (searchQuery) {
          this.selectedTags = [];
          const response = await this.getVideoFilters(searchQuery);
          const allNodeIds = this.extractIds(response);
          if (allNodeIds.length) await this.getVideos(allNodeIds, 1);

          callback(null, response);
          this.$nextTick(() => {
            this.selectedTags = allNodeIds;
            allNodeIds.forEach((tagId) => {
              const node = this.$refs.treeselect.forest.nodeMap[tagId];
              if (node.isBranch) node.isExpanded = true;
            });
            this.$nextTick(() => {
              const treeeselectElement = document.getElementById('treeselect');
              this.highlightInstance = new Mark(treeeselectElement);
              this.highlightInstance.mark(searchQuery);
            });
          });
        }
      }
    },
    async getVideosByTag(node) {
      if (!this.videos[node.id]) {
        this.videos[node.id] = {
          page: 1,
          videos: [],
          watched: 0,
          total: 0,
        };
        await this.getVideos([node.id], this.videos[node.id].page);
      }
    },
    async getVideos(videoTagIds, page) {
      this.loadingInProgress = true;
      try {
        let url = `/api/pages/hierarchy/?page=${page}`;
        videoTagIds.forEach((tagId) => { url += `&tag_ids=${tagId}`; });
        if (this.searchInProgress) {
          const { searchQuery } = this.$refs.treeselect.trigger;
          url += `&query=${searchQuery}`;
        }

        const response = await Server.get(url);
        this.userAuthenticated = response.user_authenticated;
        Object.entries(response.videos).forEach(([tagId, videoData]) => {
          if (!this.videos[tagId]) this.videos[tagId] = {};
          this.videos[tagId].videos = [
            ...this.videos[tagId].videos || [], ...videoData.results,
          ];
          this.videos[tagId].page = page;
          this.videos[tagId].hasNextPage = videoData.next !== null;
        });
      } finally {
        this.loadingInProgress = false;
      }
    },
    async getVideoFilters(query = null) {
      if (query) {
        const { treeselect } = this.$refs;
        for (const nodeId of this.$refs.treeselect.forest.selectedNodeIds) {
          treeselect.forest.nodeMap[nodeId].isExpanded = false;
        }
        this.$refs.treeselect.forest.selectedNodeIds = [];
        this.$refs.treeselect.forest.selectedNodeMap = {};
        this.selectedTags = [];
        this.lastSelectedTag = null;
      }

      let url = '/api/pages/filters/';

      if (query) url += `?query=${query}`;

      const response = await Server.get(url);
      return response;
    },
    resetSearch() {
      const { treeselect } = this.$refs;
      this.highlightInstance.unmark();
      for (const nodeId of this.$refs.treeselect.forest.selectedNodeIds) {
        treeselect.forest.nodeMap[nodeId].isExpanded = true;
      }
      this.lastSelectedTag = null;
      this.$refs.treeselect.forest.selectedNodeIds = [];
      this.$refs.treeselect.forest.selectedNodeMap = {};
      this.selectedTags = [];
      this.searchString = '';
      this.$refs.treeselect.trigger.searchQuery = '';
      this.searchVideos = {};
    },
    expandNodeBySelectedItem() {
      const { treeselect } = this.$refs;
      treeselect.forest.nodeMap[this.selectedTagId].isExpanded = true;
      for (const ancestor of treeselect.forest.nodeMap[this.selectedTagId].ancestors) {
        ancestor.isExpanded = true;
      }
    },
    async expandNode(node, loadVideos = true) {
      node = this.$refs.treeselect.forest.nodeMap[node.id];
      if (node.isBranch) node.isExpanded = !node.isExpanded;
      if (this.lastSelectedTag === node.id) {
        this.lastSelectedTag = null;
        return;
      }
      this.lastSelectedTag = node.id;
      if (loadVideos) await this.getVideosByTag(node);
      if (this.searchString) {
        this.highlightInstance.unmark();
        this.highlightInstance.mark(this.searchString);
      }
    },
    getNodeLevel(nodeId) {
      return this.$refs.treeselect.forest.nodeMap[nodeId].level;
    },
    _getVideoPreviewIframeId(page) {
      return 'treeselect-preview-' + page.preview.id;
    },
  },
  computed: {
    searchInProgress() {
      return this.searchString !== '';
    },
    videos() {
      return this.searchInProgress ? this.searchVideos : this.loadedVideos;
    },
    target() {
      if (this.videoPageId) return 'recommendation';
      return 'browse';
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .vue-treeselect__option-arrow-container {
  display: none;
}

::v-deep .vue-treeselect__option-arrow-placeholder {
  display: none;
}

::v-deep .vue-treeselect__control {
  font-size: 20px;
}

::v-deep .vue-treeselect__indent-level-0 .vue-treeselect__option {
  padding-left: 0;
}

::v-deep .vue-treeselect__indent-level-1 .vue-treeselect__option {
  padding-left: 0;
}

::v-deep .vue-treeselect__indent-level-2 .vue-treeselect__option {
  padding-left: 0;
}

::v-deep .vue-treeselect__indent-level-3 .vue-treeselect__option {
  padding-left: 0;
}

::v-deep .vue-treeselect__indent-level-4 .vue-treeselect__option {
  padding-left: 0;
}

::v-deep .vue-treeselect__control {
  display: none;
}
::v-deep .vue-treeselect__menu::-webkit-scrollbar {
  display: none;
}

::v-deep .vue-treeselect__checkbox-container {
  display: none;
}

::v-deep .vue-treeselect__option--highlight {
  background: transparent;
}

::v-deep .vue-treeselect__option {
  padding: 0;
  &:hover {
    background: transparent;
  }
}
</style>
