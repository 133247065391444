import * as helpers from '&/utils/helpers'; // eslint-disable-line import/no-useless-path-segments
import * as PfBase from '&/utils/base';
import baseCtrlMixIn from '&/utils/baseController';
import appCommentBlock from '&/components/comments';
import maxLines from '&/components/maxLines';
import jquery from 'jquery';
import VideoEventsMixin from '../mixins/VideoEventsMixin.vue';

const $ = jquery;
const { $rootScope } = PfBase;

const hlp = helpers.appHelper;
const { Server } = helpers;

(() => {})(hlp.consoleLog, $rootScope);

const CtrlMain = {
  name: 'ctrlMain',
  el: '.ctrl-base-controller',
  components: {
    appCommentBlock,
    maxLines,
  },
  mixins: [baseCtrlMixIn, VideoEventsMixin],
  data() {
    return {
      testList: ['test1', 'test2'],
      state: 'normal',
      globalMessage: {},
      search_items: [],
      search_results: false,
      search_pager: {},
      notifications_pager: {},
      currentPlayer: false,
      hpMobileShowMenu: false,
      confirmOrderEnabled: true,
      joinForm: {
        first_name: '',
        last_name: '',
        email: '',
        password1: '',
        stripeToken: '',
      },
      joinFormErrors: {
        genericError: '',
        first_name: '',
        last_name: '',
        email: '',
        password1: '',
      },
      popupVideo: {
        thumb: '',
        videoUrl: '',
        show: false,
      },
      homeVideoPlayer: null,
    };
  },
  async created() {
    this.search_results = this.search_items;
    $rootScope.$on('globalMessage', (evtData) => {
      this.globalMessage = evtData;
      this.globalMessage.show = true;
      setTimeout(() => {
        this.globalMessage.show = false;
        this.$forceUpdate();
      }, 10000);
    });

    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.handleScroll();

    if (!this.homeVideoPlayer) {
      const $autoplay = document.querySelector('#id-home-video');
      if ($autoplay) this.homeVideoPlayer = new window.Vimeo.Player($autoplay);
    }

    if (this.homeVideoPlayer) {
      this.homeVideoPlayer.getVideoId().then((vid) => {
        this.homeVideoPlayer.on('ended', () => {
          this.homeVideoPlayer.loadVideo(vid).then(() => {}).catch(() => {});
        });
      }).catch(() => {});
    }
  },
  methods: {
    replayVideo() {
      $('body').removeClass('overlay-mode');
      if (window.currentPlayer) {
        window.currentPlayer.api('play');
      }
      $('a.js-nonclickable').click(() => false);
    },
    preventSubmit(evt) {
      evt.preventDefault();
    },
    open_url(url) {
      if (url && url !== '#') {
        window.location.href = url;
      }
    },
    open_search_page(url) {
      if (url !== '#') {
        window.location.href = '/search/?q=' + url;
      }
    },
    openPopupVideo(id, thumb, videoUrl) {
      this.popupVideo.thumb = thumb;
      this.popupVideo.videoUrl = videoUrl;
      this.popupVideo.show = true;
      this.$bvModal.show(id);
    },
    closeMobileNav(el) {
      $(el).collapse('hide');
    },
    formJoinInvalid() {
      let isValid = true;
      const names = ['first_name', 'last_name', 'email', 'password1'];
      hlp.forEach(names, (name) => {
        isValid = isValid && this.joinForm[name];
      });
      return !isValid;
    },
    submitJoinForm(evt, sku) {
      const el = evt.target;
      const form = $(el).closest('form');
      const stripeToken = form.find('input[name=stripeToken]').val();
      this.joinForm.stripeToken = stripeToken;
      hlp.forEach(this.joinFormErrors, (v, k) => {
        this.joinFormErrors[k] = '';
      });
      Server.post(`/accounts/api-join/${sku}/`, this.joinForm).then((resp) => {
        if (resp.status === 'OK') {
          if (resp.url) {
            window.location.href = resp.url;
          }
        } else {
          // eslint-disable-next-line
          if (resp.errors) {
            this.joinFormErrors = resp.errors;
          } else if (resp.error) {
            this.joinFormErrors.genericError = resp.error;
          }
        }
      });
    },
    extendFeedbacks(evt) {
      $(evt.target).parent().children().first()
        .show();
      $(evt.target).hide();
    },
    scrollToElement(selector) {
      const el = this.$el.querySelector(selector);
      if (el) el.scrollIntoView({ behavior: 'smooth' });
    },
    checkVideos(videoId) {
      const element = $(`#${videoId}`);
      if (element.length) {
        const tolerancePixel = 40;
        const scrollTop = $(window).scrollTop() + tolerancePixel;
        const scrollBottom = $(window).scrollTop() + $(window).height() - tolerancePixel;
        const yTopMedia = element.offset().top;
        const yBottomMedia = element.height() + yTopMedia;

        // In case of Vimeo player
        /*
        const player = new window.Vimeo.Player(document.getElementById(videoId));
        if (scrollTop < yTopMedia && scrollBottom > yBottomMedia) {
          player.getPaused().then((paused) => {
            if (paused) player.play();
          }).catch(() => {});
        } else {
          player.getPaused().then((paused) => {
            if (!paused) player.pause();
          }).catch(() => {});
        }
        */

        const player = document.getElementById(videoId);
        if (scrollTop < yTopMedia && scrollBottom > yBottomMedia) {
          player.play();
        } else {
          player.pause();
        }
      }
    },
    handleScroll() {
      // Play videos in home page when user scroll the page
      this.checkVideos('id-preview-video-expect-1');
      this.checkVideos('id-preview-video-expect-2');
      this.checkVideos('id-preview-video-expect-3');
      this.checkVideos('id-preview-video-expect-4');
      // this.checkVideos('id-meet-instructor-video');
    },
    playHomeVideo() {
      this.homeVideoPlayer.setVolume(1).then(() => {}).catch(() => {});
      this.homeVideoPlayer.getPaused().then((paused) => {
        if (paused) {
          this.homeVideoPlayer.play();
        } else {
          this.homeVideoPlayer.pause();
        }
      });
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
export default CtrlMain;
