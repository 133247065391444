<template>
  <div
      class="tw-aspect-video tw-relative tw-text-sm lg:tw-text-base
               tw-shrink-0 tw-grow-[2] tw-basis-[100px] tw-min-h-[56px] tw-rounded-lg tw-overflow-hidden"
      @mouseenter="renderIframe = true;"
      @mouseleave="renderIframe = false; $emit('mouse-out-thumbnail')"
  >
    <div class="tw-absolute tw-cursor-pointer tw-w-full tw-h-full tw-z-50" @click="goToVideo(pageUrl)">
    </div>
    <iframe
        v-if="renderIframe && videoHost === VIDEO_HOSTS.VIMEO"
        v-show="showPreview"
        :id="iframeId"
        class="tw-w-full tw-aspect-video tw-border-0"
        border="0"
        :src="previewUrl + '?enablejsapi=1&controls=false&keyboard=false&texttrack=en-x-autogen&muted=true&playsinline=false&title=false&quality=240p&transcript=false#t=6s'"
        webkitAllowFullScreen mozallowfullscreen allowFullScreen
        video-host="vimeo"
        @load="$emit('mouse-on-thumbnail', videoId, iframeId)"
    >
    </iframe>
    <div
        class="video-container"
        :class="{
          'tw-w-px tw-h-px': !youtubePlayerReady
        }"
        v-if="renderIframe && videoHost === VIDEO_HOSTS.YOUTUBE"
    >
      <div
          :id="iframeId"
          v-if="renderIframe && videoHost === VIDEO_HOSTS.YOUTUBE"
          v-show="showPreview"
          class="tw-w-full tw-h-full tw-aspect-video tw-border-0"
          border="0"
          :src="previewUrl + '?enablejsapi=1&autoplay=0&mute=1&controls=0&modestbranding=1&rel=0&fs=0&iv_load_policy=3&autohide=1'"
          webkitAllowFullScreen mozallowfullscreen allowFullScreen
          video-host="youtube"
      > <!-- for YT videos "mouse-on-thumbnail" is emitted via watcher, as there is no iframe to use @load" -->
      </div>
    </div>

    <img
        v-show="!videoHost || (videoHost === VIDEO_HOSTS.VIMEO && !showPreview) || (videoHost === VIDEO_HOSTS.YOUTUBE && !youtubePlayerReady)"
        class="tw-w-full tw-aspect-video"
        :class="{
          'tw-blur': renderIframe && ((videoHost === VIDEO_HOSTS.VIMEO && !showPreview) || (videoHost === VIDEO_HOSTS.YOUTUBE && !youtubePlayerReady))
        }"
        :src="thumbnailUrl || '/static/img/gsa-instruction-default.jpg'"
        :alt="title"
    >
    <div
        v-if="userAuthenticated && isWatched && !showPreview"
        class="tw-absolute tw-bottom-auto tw-left-1 tw-top-1 @md:tw-left-4 @md:tw-top-4 tw-py-0.5 tw-px-1 tw-bg-black
               tw-text-sm @sm:tw-text-base tw-bg-opacity-60 tw-text-white tw-rounded-sm tw-font-semibold tw-leading-[19px]"
        :id="'watchedContainer' + videoId"
    >
        <span v-if="isWatched">WATCHED</span>
    </div>
    <span
        v-if="videoDuration && !showPreview"
        class="
            tw-absolute tw-bottom-1 tw-right-0 tw-py-0.5 tw-px-1
            tw-bg-black tw-text-sm @sm:tw-text-base tw-bg-opacity-60 tw-text-white tw-rounded-sm tw-font-semibold tw-leading-[19px]
          ">
          {{ videoDuration }}
      </span>
    <div v-if="watchedPercent > 0" class="tw-absolute tw-bottom-0 tw-w-full tw-h-1">
      <div class=" tw-flex tw-w-full tw-h-[inherit]">
        <div class="tw-bg-red-600 tw-rounded-bl-full" :style="{width: `${watchedPercent}%`}"></div>
        <div class="tw-grow tw-bg-zinc-300 tw-rounded-br-full"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { BIcon } from 'bootstrap-vue';
import { VIDEO_HOSTS } from '../utils/constants.js';
import { $rootScope } from '../utils/base.js';

export default {
  name: 'VideoPreviewThumbnail',
  components: { BIcon },
  mixins: [],
  props: {
    pageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    thumbnailUrl: {
      type: String,
      required: true,
    },
    previewUrl: {
      type: String,
      required: false,
    },
    isWatched: {
      type: Boolean,
      required: true,
    },
    watchedPercent: {
      type: Number,
      required: false,
    },
    videoDuration: {
      type: String,
      required: true,
    },
    videoId: {
      type: Number,
      required: true,
    },
    videoHost: {
      type: Number,
      required: true,
    },
    userAuthenticated: {
      type: Boolean,
      required: true,
    },
    showPreview: {
      type: Boolean,
      required: true,
    },
    iframeId: {
      type: String,
      required: true,
    },
  },
  emits: ['mouse-on-thumbnail', 'mouse-out-thumbnail'],
  data() {
    return {
      renderIframe: false,
      youtubePlayerReady: false,
    };
  },
  computed: {
    VIDEO_HOSTS() {
      return VIDEO_HOSTS;
    },
  },
  setup() {},
  created() {
    if (this.videoHost === VIDEO_HOSTS.YOUTUBE) {
      $rootScope.$on('YtVideo_play', (iframeId) => {
        if (iframeId === this.iframeId) {
          this.youtubePlayerReady = true;
        }
      });
    }
  },
  methods: {
    goToVideo() {
      window.open(this.pageUrl, '_self');
    },
  },
  watch: {
    renderIframe(newVal) {
      if (this.videoHost === VIDEO_HOSTS.YOUTUBE) {
        if (!newVal) {
          this.youtubePlayerReady = false;

          // for some reason YT player not removed and attaches iframe to WATCHED-span, so need to delete it and recreate the span
          document.getElementById(this.iframeId).remove();
          if (this.isWatched) {
            const watched = document.createElement('span');
            watched.textContent = 'WATCHED';
            setTimeout(() => {
              document.getElementById('watchedContainer' + this.videoId).appendChild(watched);
            }, 300);
          }
        } else {
          setTimeout(() => {
            this.$emit('mouse-on-thumbnail', this.videoId, this.iframeId);
          }, 10);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
