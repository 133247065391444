<template>
  <div class="container-fluid">
    <div class="row mt-2">
      <div class="col-md-3">
        <div class="list-group">
          <a
              class="list-group-item list-group-item-action"
              @click="_changeTab('watch')"
              :class="{active: historyTab === 'watch'}"
          >
            Watch History
          </a>
          <a
              class="list-group-item list-group-item-action"
              @click="_changeTab('comments')"
              :class="{active: historyTab === 'comments'}"
          >
            Comments
          </a>
          <a
              @click="_changeTab('notif')"
              :class="{active: historyTab === 'notif'}"
              class="list-group-item list-group-item-action">
            Notifications
          </a>
        </div>
      </div>
      <div class="col-md-9" v-cloak>
        <div class="tabs-content">
          <div class="tabs-panel" id="panel1v" v-if="historyTab === 'watch'">
            <div v-show="itemsCount">
              <h4 ref="items-count">
                {{ itemsCount }} pages viewed
              </h4>
              <a class="text-primary" @click="deleteWatchHistory(null)">
                Clear All Watch History
              </a>
            </div>
            <span v-show="!itemsCount">No Records Found.</span>
            <div
                v-for="historyItem of historyItems"
                :key="historyItem.id"
                class="media tw-flex-wrap position-relative border-bottom py-3 pr-4"
            >
              <video-preview-thumbnail
                  class="tw-max-w-[295px]"
                  :video-host="historyItem.page.preview.host"
                  :show-preview="currentPlayingPreviewVideoId === historyItem.page.preview.id"
                  :iframe-id="`history-preview-` + historyItem.page.preview.id"
                  :video-id="historyItem.page.preview.id"
                  :page-url="historyItem.page.url"
                  :thumbnail-url="historyItem.page.thumbnail"
                  :preview-url="historyItem.page.preview_url"
                  :title="historyItem.page.title"
                  :user-authenticated="true"
                  :is-watched="historyItem.page.is_watched"
                  :watched-percent="historyItem.page.watched_percent"
                  :video-duration="historyItem.page.video_duration_minutes"
                  @mouse-on-thumbnail="(videoId) => mouseOnVideoThumbnail(historyItem.page.preview.id, 'history-preview-' + historyItem.page.preview.id)"
                  @mouse-out-thumbnail="mouseOutVideoThumbnail"
              ></video-preview-thumbnail>
              <div class="media-body ml-3 tw-mt-auto md:tw-mt-0 tw-flex tw-flex-col md:tw-h-[166px]">
                <h6 class="tw-hidden md:tw-block">
                  <a
                      :href="'/golf-instruction/' + historyItem.page.slug"
                  >
                    {{ historyItem.page.title }}
                  </a>
                </h6>
                <div
                    class="description tw-hidden md:tw-block"
                >
                  <span class="tw-text-ellipsis tw-overflow-hidden tw-line-clamp-4">
                    {{ historyItem.page.description }}
                  </span>
                </div>
                <div class="small tw-mt-auto tw-block">
                  <em>Last viewed at {{ dayjs(historyItem.date_modified).format('MMM D, YYYY HH:mm') }}</em>
                </div>
              </div>
              <button
                  @click="deleteWatchHistory(historyItem.page.id)"
                  class="close-button -tw-right-2.5 tw-bg-transparent hover:tw-opacity-70 tw-border-0"
              >
                <b-icon
                    icon="x"
                    class="tw-h-8 tw-w-8"
                ></b-icon>
              </button>
              <h6 class="tw-block md:tw-hidden tw-mt-2 tw-w-full">
                  <a
                      :href="'/golf-instruction/' + historyItem.page.slug"
                  >
                    {{ historyItem.page.title }}
                  </a>
                </h6>
            </div>
            <simple-pager
                class="tw-mt-2"
                :page="currentPage"
                :limit="10"
                :count="itemsCount"
                @page-changed="_onPageChanged"
            />
          </div>

          <div class="tabs-panel" id="panel2v" v-if="historyTab === 'comments'">
            <div v-show="itemsCount">
              <h4 ref="items-count">
                {{ itemsCount }} comments
              </h4>
              <a class="text-primary" @click="deleteComment(null)">
                Clear All Comments History
              </a>
            </div>
            <span v-show="!itemsCount">No Records Found.</span>
            <div
                v-for="comment of historyItems"
                v-if="!comment.is_removed"
                :data-pk="comment.id"
                class="comment_section position-relative border-bottom py-3 pr-4"
            >
              <div>
                <div class="" @click="openCommentPage(comment)">
                  <p class="page">
                    Commented on
                    <a
                        v-if="comment.content_type ==='page'"
                        :href="'/golf-instruction/' + comment.page_slug"
                    >
                      {{ comment.page_title }}
                    </a>
                    <a
                        v-if="comment.content_type === 'submission'"
                        :href="'/swing-analysis/submission/' + comment.object_pk">
                      {{ comment.page_title }}
                    </a>
                  </p>
                  <div>{{ comment.comment }}</div>
                  <div
                      class="date small"
                      :title="comment.submit_date_ago"
                  >
                    {{ comment.submit_date_ago }}
                  </div>
                </div>
                <button
                    @click="deleteComment(notify.id)"
                    class="close-button -tw-right-2.5 tw-bg-transparent hover:tw-opacity-70 tw-border-0"
                >
                  <b-icon
                      icon="x"
                      class="tw-h-8 tw-w-8"
                  ></b-icon>
                </button>
              </div>
            </div>
            <simple-pager
                class="tw-mt-2"
                :page="currentPage"
                :limit="10"
                :count="itemsCount"
                @page-changed="_onPageChanged"
            />
          </div>

          <div class="tabs-panel" id="panel4v" v-if="historyTab === 'notif'">
            <div v-show="itemsCount">
              <h4 ref="items-count">
                {{ itemsCount }} Notifications
              </h4>
              <a class="text-primary" @click="deleteNotification(null)">
                Clear All Notifications
              </a>
            </div>
            <span v-show="!itemsCount">No Notifications Found.</span>

            <div v-cloak class="people-you-might-know">
              <div>
                <div v-for="notify in historyItems"
                     class="add-people-section notification position-relative border-bottom py-3 pr-4">
                  <div :data-nf-id="notify.id" :id="'nf-' + notify.id" :class="notify.width_class_small"
                       class="about-people notification read">

                    <div @click="goToUrl(notify.target_url)" class="about-people-author media"
                         :class="{pointer: notify.target_url && notify.target_url !== '#'}">
                      <div class="media-body ml-3" :class="{pointer: notify.target_url !== '#'}"
                           @click="goToUrl(notify.target_url)">
                        <p v-show="notify.user === 'GSA System'">
                          <strong v-text="notify.user"></strong> &quot;<span v-text="notify.comment"></span>&quot;
                        </p>
                        <p v-show="notify.user !== 'GSA System'">
                          <strong v-text="notify.user"></strong>
                          replied: &quot;<span v-text="notify.comment"></span>&quot;
                        </p>
                        <div class="author-location small text-secondary">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                          <span v-text="notify.at"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                      @click="deleteNotification(notify.id)"
                      class="close-button -tw-right-2.5 tw-bg-transparent hover:tw-opacity-70 tw-border-0"
                  >
                    <b-icon
                        icon="x"
                        class="tw-h-8 tw-w-8"
                    ></b-icon>
                  </button>
                </div>
              </div>
            </div>
            <simple-pager
                class="tw-mt-2"
                :page="currentPage"
                :limit="10"
                :count="itemsCount"
                @page-changed="_onPageChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseCtrlMixIn from '../utils/baseController.js';
import VideoPreviewMixin from '../mixins/VideoPreviewMixin.vue';
import VideoPreviewThumbnail from '../components/VideoPreviewThumbnail.vue';
import { Deferred, Server } from '../utils/helpers.js';
import * as helpers from '../utils/helpers.js';
import dayjs from 'dayjs';
import SimplePager from '../components/simplePager.vue';

const hlp = helpers.appHelper;

export default {
  name: 'CtrlPageHistory',
  el: '#history-page-main-content',
  mixins: [baseCtrlMixIn, VideoPreviewMixin],
  components: { SimplePager, VideoPreviewThumbnail },
  data() {
    return {
      historyTab: 'watch',
      historyItems: [],
      itemsCount: 0,
      currentPage: 1,
    };
  },
  async created() {
    await this.getWatchedPagesHistory(1); // {"length": paging.count, 'pages': paging.num_pages, "currentPage": page}
  },
  mounted() {
    $('[v-cloak]').get().forEach((element) => { element.removeAttribute('v-cloak'); });
  },
  methods: {
    dayjs,
    getWatchedPagesHistory(page) {
      const url = '/api/page/history/?page=' + page;
      this.currentPage = page;
      Server.get(url).then((data) => {
        this.historyItems = data.results;
        this.itemsCount = data.count;
      });
    },
    getUserComments(page) {
      this.currentPage = page;
      const url2 = '/api/comments/list/?page=' + page;
      Server.get(url2).then((data) => {
        this.historyItems = data.comments;
        this.itemsCount = data.not_removed_count;
      });
    },
    getUserNotificationsHistory(page) {
      this.currentPage = page;
      const url2 = '/api/notifications/?page=' + page;
      Server.get(url2).then((data) => {
        this.historyItems = data.notifications;
        this.itemsCount = data.paginator.length;
      });
    },
    deleteHistoryItem(pk, type, msgAll, msgOne) {
      const promiseObj = new Deferred();
      const typeMap = {
        notif: 'notifications',
        comments: 'comments_view',
        watch: 'page/history',
      };
      const endpoint = typeMap[type];
      const callDelete = (url) => {
        Server.delete(url).then(() => {
          promiseObj.resolve(promiseObj);
        }, (dt) => {
          promiseObj.reject(dt);
        });
        // setTimeout(() => { promiseObj.resolve(); });
      };
      if (endpoint) {
        let url = `/api/${endpoint}/`;
        let confirmMsg = null;
        if (pk) {
          url = `${url}${pk}/`;
          confirmMsg = msgOne;
        } else {
          confirmMsg = msgAll;
        }
        if (confirmMsg) {
          this.confirm(confirmMsg).then((result) => {
            if (!result) {
              promiseObj.reject();
            } else {
              callDelete(url);
            }
          });
        } else {
          callDelete(url);
        }
      } else {
        setTimeout(() => {
          promiseObj.reject();
        });
      }
      return promiseObj.promise;
    },
    deleteNotification(pk, page) {
      this.deleteHistoryItem(pk, 'notif', 'Do you want to clear your Notifications history?',
        'Do you want to remove this Notification?').then(() => {
        this.getUserNotificationsHistory(page);
      }, () => {});
    },
    deleteComment(pk) {
      this.deleteHistoryItem(pk, 'comments', 'Do you want to clear your Comments history?',
        'Do you want to remove this comment?').then(() => {
        this.getUserComments(this.currentPage);
      }, () => {});
    },
    async deleteWatchHistory(pk) { // , page
      await this.deleteHistoryItem(
        pk,
        'watch',
        'Do you want to clear your watch history?',
        'Do you want to remove this item?',
      );
      this.getWatchedPagesHistory(this.currentPage);
    },
    openCommentPage(comment) {
      let url = '';
      if (comment.content_type === 'page') {
        url = '/golf-instruction/' + comment.page_slug;
      }
      if (comment.content_type === 'submission') {
        url = '/swing-analysis/submission/' + comment.object_pk;
      }
      if (url !== '#') {
        window.location.href = url;
      }
    },
    async _onPageChanged(newPage) {
      if (this.historyTab === 'watch') await this.getWatchedPagesHistory(newPage);
      if (this.historyTab === 'comments') await this.getUserComments(newPage);
      if (this.historyTab === 'notif') await this.getUserNotificationsHistory(newPage);

      const { offsetTop } = this.$refs['items-count'];
      $(window).scrollTop(offsetTop);
    },
    _changeTab(newTabName) {
      this.historyTab = newTabName;
      this.itemsCount = null;
      this.historyItems = [];

      if (this.historyTab === 'watch') this.getWatchedPagesHistory(1);
      if (this.historyTab === 'comments') this.getUserComments(1);
      if (this.historyTab === 'notif') this.getUserNotificationsHistory(1);
    },
  },
};
</script>
