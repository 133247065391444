<template>
  <div
      class="
        tw-flex tw-grow tw-items-center tw-gap-x-2 tw-py-2 -tw-my-2
      "
      :class="{
        'tw-bg-gray-200': isHighlighted,
        'hover:tw-bg-zinc-100': !isHighlighted
      }"
  >
    <span class="tw-inline-flex tw-items-center tw-justify-center tw-w-7">
      <span class="tw-font-normal tw-text-sm @sm:tw-text-lg " v-if="order && !isHighlighted">{{ order }} </span>
      <b-icon
          v-if="isHighlighted"
          icon="triangle-fill"
          class="tw-text-blue-1 tw-rotate-90 tw-h-3 tw-w-3"
      />
    </span>
    <video-preview-thumbnail
      :video-host="videoHost"
      :show-preview="showPreview"
      :iframe-id="iframeId"
      :video-id="videoId"
      :page-url="pageUrl"
      :preview-url="previewUrl"
      :thumbnail-url="thumbnailUrl"
      :title="title"
      :user-authenticated="userAuthenticated"
      :is-watched="isWatched"
      :watched-percent="watchedPercent"
      :video-duration="videoDuration"
      @mouse-on-thumbnail="$emit('mouse-on-thumbnail', videoId, iframeId)"
      @mouse-out-thumbnail="$emit('mouse-out-thumbnail')"
    />
    <div class="tw-flex tw-h-full tw-flex-wrap tw-self-start tw-basis-[150px] tw-grow">
      <div class="tw-flex tw-items-start tw-justify-between tw-grow">
        <a lang="en" :href="pageUrl" class="tw-mr-2 !tw-text-black hover:!tw-no-underline">
          <h6
              class="tw-basis-[100px] tw-mb-0 tw-text-wrap tw-font-normal tw-text-sm
                     @sm:tw-text-lg tw-self-start tw-grow tw-line-clamp-2 tw-text-ellipsis"
          >
            <span>{{ title }}</span>
          </h6>
        </a>
        <watch-later-modal
            class="tw-min-w-[24px] tw-flex tw-justify-center"
            :is-authenticated="$root.isAuthenticated"
            :page-id="pageId"
            icon="three-dots-vertical"
            icon-classes="tw-h-6 tw-w-6"
            @signInTriggered="$root.openAuthBox($event, fullPath)"
            @signUpTriggered="$root.showModal('signup')"
        ></watch-later-modal>
      </div>
      <div class="tw-w-full tw-mt-auto tw-text-sm @sm:tw-text-lg tw-font-normal tw-text-neutral-500">
        {{ getXTimeAgo(publishDate) }}
      </div>
    </div>
  </div>
</template>

<script>
import { BIcon } from 'bootstrap-vue'
import { getXTimeAgo } from '../utils/helpers.js'
import { VIDEO_HOSTS } from '../utils/constants.js'
import VideoPreviewThumbnail from './VideoPreviewThumbnail.vue'

export default {
  name: 'RecommendedVideoCard',
  emits: ['mouse-on-thumbnail', 'mouse-out-thumbnail'],
  props: {
    pageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    thumbnailUrl: {
      type: String,
      required: true,
    },
    previewUrl: {
      type: String,
      required: false,
    },
    isWatched: {
      type: Boolean,
      required: true,
    },
    watchedPercent: {
      type: Number,
      required: false,
    },
    videoDuration: {
      type: String,
      required: true,
    },
    videoId: {
      type: Number,
      required: true,
    },
    videoHost: {
      type: Number,
      required: true,
    },
    userAuthenticated: {
      type: Boolean,
      required: true,
    },
    showPreview: {
      type: Boolean,
      required: true,
    },
    order: {
      type: Number,
      required: false,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    pageId: {
      type: Number,
      required: true,
    },
    publishDate: {
      type: String,
      required: true,
    },
    iframeId: {
      type: String,
      required: true,
    },
  },
  components: { VideoPreviewThumbnail, BIcon },
  computed: {
    VIDEO_HOSTS () {
      return VIDEO_HOSTS
    },
    croppedDescription () {
      if (this.description.length <= 85) return this.description
      return this.description.slice(0, 85) + '...'
    },
    fullPath () {
      return window.location.pathname + window.location.search
    },
  },
  methods: {
    getXTimeAgo
  },
}
</script>

<style lang="scss" scoped>
</style>
