<template>
  <vue-resizable
      ref="resizable"
      class="tw-pr-2 tw-border-0 tw-border-r tw-border-r-gray-300 tw-border-solid"
      :fit-parent="false"
      :disable-attributes="['h']"
      :width="blockWidth"
      :min-width="initialWidth"
      :max-width="870"
      height="auto"
      :active="isCollapsed ? [] : ['r']"
  >
    <div
        class="tw-hidden md:tw-flex tw-justify-between tw-mb-4"
        :class="{
          'tw-flex-col tw-gap-3': isCollapsed
        }"
    >
      <b-icon
          class="tw-ml-0.5 tw-w-5 tw-h-5 tw-cursor-pointer"
          @click="$emit('home-btn-clicked')"
          icon="house-door"
          size="20">
      </b-icon>
      <b-icon
          v-show="!isCollapsed"
          class="tw-w-5 tw-h-5 tw-cursor-pointer"
          @click="collapseSidebar"
          icon="box-arrow-in-left"
          size="20">
      </b-icon>
      <b-icon
          v-show="isCollapsed"
          class="tw-w-5 tw-h-5 tw-cursor-pointer"
          @click="expandSidebar"
          icon="box-arrow-in-right"
          size="20">
      </b-icon>
    </div>
    <div :class="{
      'tw-invisible': isCollapsed
    }">
      <slot></slot>
    </div>

  </vue-resizable>
</template>

<script>
import VueResizable from 'vue-resizable';
import { BIcon } from 'bootstrap-vue';

import * as helperMod from '&/utils/helpers';

const helper = helperMod.appHelper;

export default {
  name: 'ExpandableBlock',
  components: { VueResizable, BIcon },
  mixins: [],
  props: {
    initialWidth: {
      type: Number,
      default: 350,
    },
    triggerFullWidthPx: {
      type: Number,
      default: 767,
    },
  },
  emits: ['home-btn-clicked'],
  data() {
    return {
      isCollapsed: false,
      expandedSidebarWidth: null,
      windowWidth: window.innerWidth,
      blockWidth: this.initialWidth,
    };
  },
  created() {
    window.addEventListener('resize', this.updateWindowSize);
  },
  methods: {
    collapseSidebar() {
      this.isCollapsed = !this.isCollapsed;
      this.expandedSidebarWidth = this.$refs.resizable.w;
      this.$refs.resizable.w = 40;
    },
    expandSidebar() {
      this.isCollapsed = !this.isCollapsed;
      this.$refs.resizable.w = this.expandedSidebarWidth;
    },
    updateWindowSize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
  },
  computed: {
    isMobileDev() {
      return helper.isMobileDev();
    },
  },
  watch: {
    windowWidth() {
      this.blockWidth = helper.testMaxWidth(this.triggerFullWidthPx) ? this.windowWidth : this.initialWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
