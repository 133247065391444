<script>
import { appHelper as helpers, appHelper as helper, extractYoutubeEmbeddedVideoCode } from '../utils/helpers.js'
import VideoPlayerFacade from '../utils/VideoPlayerFacade.js';

export default {
  name: 'VideoPreviewMixin',
  data() {
    return {
      currentPlayingPreviewVideoId: null,
      currentPlayer: null,
    };
  },
  methods: {
    mouseOnVideoThumbnail(videoId, iframeId) {
      if (helper.isMobileDev()) {
        return;
      }
      this.currentPlayingPreviewVideoId = videoId;
      this.loadPreview(iframeId);
      this.currentPlayer.play();
      this.$emit('video-preview-playing', true)
    },
    mouseOutVideoThumbnail() {
      if (!this.currentPlayer) return;
      this.currentPlayer.pause();
      this.currentPlayer.setCurrentTime(6);
      this.$emit('video-preview-playing', false)
      this.currentPlayer = null;
      this.currentPlayingPreviewVideoId = null;
    },
    loadPreview(iframeId) {
      const iframe = $('#' + iframeId);
      const attrs = helpers.getAttrs(iframe);
      const videoKey = extractYoutubeEmbeddedVideoCode(attrs.src)
      this.currentPlayer = new VideoPlayerFacade(attrs.videoHost, attrs.id, videoKey);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
